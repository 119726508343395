<template>
  <v-dialog v-model="dialog" max-width="450" persistent>
    <v-card>
      <v-card-text class="text-h6 pt-6" style="text-align: center">
        {{ message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      message: "",
    };
  },
  methods: {
    open(message) {
      this.message = message;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
