<template>
  <div>
    <v-row dense>
      <v-col>
        <scheduling-risk-location-card />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <scheduling-unity-card />
      </v-col>
    </v-row>

    <v-row dense v-show="false">
      <v-col>
        <Schedule />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <LeadVideoCallsCard />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <LeadSchedulingList
          :lead_identifier_code="$route.params['lead_number']"
        ></LeadSchedulingList>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <scheduling-analysis-card />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="mt-1" outlined>
          <v-card-title>{{ __("Pesquisa", "schedulings") }} </v-card-title>
          <v-list>
            <DateListItem
              ref="research_initial_date"
              @update="
                updateDate(
                  $event,
                  'research_initial_date',
                  'research_begin_date'
                )
              "
              :timestamp="lead.default_service.research_begin_date"
              :label="__('Data de início', 'schedulings')"
              readonly
            />

            <DateListItem
              ref="research_estimated_date"
              @update="
                updateDate(
                  $event,
                  'research_estimated_date',
                  'research_estimated_end_date'
                )
              "
              :timestamp="lead.default_service.research_estimated_end_date"
              :label="__('Data prevista para finalização', 'schedulings')"
            />

            <DateListItem
              ref="research_end_date"
              @update="
                updateDate($event, 'research_end_date', 'research_end_date')
              "
              :timestamp="lead.default_service.research_end_date"
              :label="__('Data de finalização', 'schedulings')"
            />
          </v-list>
        </v-card>
      </v-col>

      <v-col>
        <v-card class="mt-1" outlined>
          <v-card-title>{{ __("Obra", "schedulings") }} </v-card-title>
          <v-list>
            <DateListItem
              ref="construction_begin_date"
              @update="
                updateDate(
                  $event,
                  'construction_begin_date',
                  'construction_begin_date'
                )
              "
              :timestamp="lead.default_service.construction_begin_date"
              :label="__('Data de início', 'schedulings')"
              readonly
            />
            <DateListItem
              ref="construction_estimated_end_date"
              @update="
                updateDate(
                  $event,
                  'construction_estimated_end_date',
                  'construction_estimated_end_date'
                )
              "
              :timestamp="lead.default_service.construction_estimated_end_date"
              :label="__('Data prevista para finalização', 'schedulings')"
            />
            <DateListItem
              ref="construction_end_date"
              @update="
                updateDate(
                  $event,
                  'construction_end_date',
                  'construction_end_date'
                )
              "
              :timestamp="lead.default_service.construction_end_date"
              :label="__('Data de finalização', 'schedulings')"
            />
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="contact_attempt_dialog" max-width="600">
      <v-card>
        <v-form @submit.prevent="addContactAttempt">
          <v-card-title>{{
            __("Tentativas de contato", "schedulings")
          }}</v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="contact_attempt_form.participant_id"
              :items="participants"
              hide-no-data
              hide-selected
              item-text="contact.name"
              item-value="id"
              :label="__('Contratado', 'schedulings')"
              :placeholder="
                __('Comece a digitar para buscar o participante', 'schedulings')
              "
              prepend-icon="mdi-database-search"
            ></v-autocomplete>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="contact_attempt_form.date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="contact_attempt_form.date"
                  :label="__('Data', 'schedulings')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="contact_attempt_form.date"
                no-title
                scrollable
                :max="new Date().toISOString().substr(0, 10)"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">{{
                  __("Cancelar", "schedulings")
                }}</v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(contact_attempt_form.date)"
                  >{{ __("Ok", "schedulings") }}</v-btn
                >
              </v-date-picker>
            </v-menu>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="contact_attempt_form.time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="contact_attempt_form.time"
                  :label="__('Hora', 'schedulings')"
                  prepend-icon="mdi-clock"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menu2"
                format="24hr"
                v-model="contact_attempt_form.time"
                full-width
                @click:minute="$refs.menu2.save(contact_attempt_form.time)"
              ></v-time-picker>
            </v-menu>
            <v-radio-group v-model="contact_attempt_form.success">
              <template v-slot:label>{{
                __("Sobre a tentativa de contato", "schedulings")
              }}</template>
              <v-radio :value="true">
                <template v-slot:label>{{
                  __("Sucesso", "schedulings")
                }}</template>
              </v-radio>
              <v-radio :value="false">
                <template v-slot:label>{{
                  __("Insucesso", "schedulings")
                }}</template>
              </v-radio>
            </v-radio-group>
            <v-text-field
              :label="__('Justificativa', 'schedulings')"
              v-model="contact_attempt_form.justification"
              counter="512"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="contact_attempt_dialog = false">{{
              __("Cancelar", "schedulings")
            }}</v-btn>
            <v-btn type="submit" color="primary">{{
              __("Salvar", "schedulings")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <confirm-action-dialog
      :confirm_action="contact_attempt_deletion"
      @change="contact_attempt_deletion = $event"
      @action="removeContactAttempt()"
      :message="
        __(
          'A tentativa de contato será permanentemente excluída',
          'schedulings'
        )
      "
    ></confirm-action-dialog>
  </div>
</template>

<script>
import ConfirmActionDialog from "../../ConfirmActionDialog";
import { index as indexSchedulings } from "@/services/scheduling";
import {
  indexContactAttempt,
  destroyContactAttempt,
} from "@/services/contact_attempts";
import SchedulingRiskLocationCard from "@/components/Lead/Scheduling/SchedulingRiskLocationCard";
import SchedulingUnityCard from "@/components/Lead/Scheduling/SchedulingUnityCard";
import SchedulingAnalysisCard from "@/components/Lead/Scheduling/SchedulingAnalysisCard";
import Schedule from "@/components/Lead/Scheduling/Schedule";
import { update as updateService } from "@/services/services";
import { formatDateTime } from "@/tools/date";
import DateListItem from "@/components/DateListItem";
import LeadVideoCallsCard from "@/components/video_calls/LeadVideoCallsCard.vue";
import LeadSchedulingList from "../../LeadScheduling/LeadSchedulingList.vue";
import i18n from "@/mixins/i18n";

export default {
  mixins: [i18n],
  components: {
    Schedule,
    ConfirmActionDialog,
    SchedulingRiskLocationCard,
    SchedulingUnityCard,
    SchedulingAnalysisCard,
    DateListItem,
    LeadVideoCallsCard,
    LeadSchedulingList,
  },
  data() {
    return {
      formatDateTime,
      contact_attempts: [],
      contact_attempt_deletion: false,
      contact_attempt_delete_id: null,
      contact_attempt_loading: false,
      contact_attempt_dialog: false,
      contact_attempt_form: {},
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      showAll: false,

      research_loading: false,
      research_form: {
        research_begin_date: null,
        research_estimated_end_date: null,
        research_end_date: null,
      },
      research_initial_date: null,
      research_start_time: null,
      research_estimated_date: null,
      research_estimated_time: null,
      research_final_date: null,
      research_final_time: null,

      construction_loading: false,
      construction_form: {
        construction_begin_date: null,
        construction_estimated_end_date: null,
        construction_end_date: null,
      },
      construction_start_date: null,
      construction_start_time: null,
      construction_estimated_date: null,
      construction_estimated_time: null,
      construction_final_date: null,
      construction_end_time: null,
    };
  },
  methods: {
    async handleUpdateConstruction(attribute, value) {
      this.construction_loading = true;

      try {
        const payload = {
          [attribute]: value,
        };

        const response = await updateService(
          payload,
          this.lead.default_service.id
        );

        this.$store.commit("setLead", {
          ...this.lead,
          default_service: {
            ...this.lead.default_service,
            construction_begin_date: response.construction_begin_date,
            construction_estimated_end_date:
              response.construction_estimated_end_date,
            construction_end_date: response.construction_end_date,
          },
        });

        this.$store.commit("sendMessage", {
          text: "Atualizando as datas de obra",
          color: "green",
        });
      } catch (error) {
        this.$store.commit("error", error.response.data.message);
      } finally {
        this.construction_loading = false;
      }
    },
    async updateDate(dateTime, elementRef, payloadKey) {
      this.$refs[elementRef].loading = true;

      try {
        const payload = {
          [payloadKey]: `${dateTime.date} ${dateTime.time}`,
        };

        const response = await updateService(
          payload,
          this.lead.default_service.id
        );

        this.$store.commit("setLead", {
          ...this.lead,
          default_service: {
            ...this.lead.default_service,
            [payloadKey]: response[payloadKey],
          },
        });
        this.loadDatesLeadInfo();

        this.$refs[elementRef].close();

        this.$store.commit("sendMessage", {
          text: "Salvo",
        });
      } catch (error) {
        this.$store.commit("error", error);
        if (error.response.status === 422) {
          this.$refs[elementRef].setErrors(
            error.response.data.errors[payloadKey]
          );
        }
      } finally {
        this.$refs[elementRef].loading = false;
      }
    },
    getContactAttemptDuration(attempt) {
      const attempt_moment = this.$moment(`${attempt.date} ${attempt.time}`);
      const now = this.$moment();

      return this.$moment.duration(attempt_moment.diff(now)).humanize(true);
    },
    async getSchedulings() {
      this.scheduling_loading = true;

      try {
        const schedulings = await indexSchedulings(
          this.$route.params["lead_number"]
        );
        this.schedulings = schedulings.map((scheduling) => {
          const date = this.$moment(scheduling.date + " " + scheduling.time);

          return {
            name:
              scheduling.participant?.contact?.name.substring(0, 10).trim() +
              "...",
            start: date.toDate(),
            color: scheduling.type.color,
            timed: true,
            edit: () => this.editScheduling(scheduling),
          };
        });
      } finally {
        this.scheduling_loading = false;
      }
    },
    async getContactAttempts() {
      this.contact_attempt_loading = true;
      try {
        this.contact_attempts = [];

        const response = await indexContactAttempt({
          lead_identifier_code: this.$route.params["lead_number"],
        });

        this.contact_attempts = response;
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: "Falha ao carregar tentativas de contactos",
          color: "red",
        });
      } finally {
        this.contact_attempt_loading = false;
      }
    },
    confirmDeleteContactAttempt(id) {
      this.contact_attempt_delete_id = id;
      this.contact_attempt_deletion = true;
    },
    async addContactAttempt() {
      try {
        const response = await this.$http.post("/contact_attempts", {
          ...this.contact_attempt_form,
          lead_id: this.lead.id,
        });
        this.contact_attempt_form = {};
        this.contact_attempt_dialog = false;
        this.$store.commit("sendMessage", {
          text: "Tentativa de contacto adicionada",
        });
        this.getContactAttempts();
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: "Falha ao adicionar tentativa de contacto",
          color: "red",
        });
      }
    },
    async removeContactAttempt() {
      this.contact_attempt_loading = true;
      try {
        await destroyContactAttempt(this.contact_attempt_delete_id);
        this.contact_attempts = this.contact_attempts.filter(
          (attempt) => attempt.id !== this.contact_attempt_delete_id
        );

        this.$store.commit("sendMessage", {
          text: "Tentativa de contacto removida",
        });

        this.contact_attempt_deletion = false;
        this.contact_attempt_delete_id = null;
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: error.response.data.message,
          color: "red",
        });
      } finally {
        this.contact_attempt_loading = false;
      }
    },
    loadDatesLeadInfo() {
      this.research_form.research_begin_date =
        this.lead.default_service.research_begin_date;
      this.research_form.research_estimated_end_date =
        this.lead.default_service.research_estimated_end_date;
      this.research_form.research_end_date =
        this.lead.default_service.research_end_date;
      this.construction_form.construction_begin_date =
        this.lead.default_service.construction_begin_date;
      this.construction_form.construction_estimated_end_date =
        this.lead.default_service.construction_estimated_end_date;
      this.construction_form.construction_end_date =
        this.lead.default_service.construction_end_date;
    },
  },
  created() {
    this.getContactAttempts();
    this.loadDatesLeadInfo();
  },
  computed: {
    schedulings: function () {
      return this.$store.getters["schedulings/getLeadSchedulings"];
    },
    lead: function () {
      return this.$store.getters.getLead;
    },
    participants: function () {
      return this.lead ? this.lead.participants : [];
    },
    failed_contact_attempts: function () {
      return this.contact_attempts.filter((attempt) => {
        if (this.showAll) {
          return true;
        } else {
          return attempt.success === false;
        }
      });
    },
  },
  watch: {
    lead: function (value, oldValue) {
      if (oldValue?.id == null && value?.id) {
        this.loadDatesLeadInfo();
      }
    },
  },
};
</script>
