<template>
  <v-card outlined>
    <v-card-title>
      Agendamentos
      <v-btn @click="loadSchedules()" icon>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="handleClickNewScheduling()" color="primary">
        Adicionar
      </v-btn>
    </v-card-title>

    <v-switch
      v-model="showCanceled"
      label="Exibir canceladas"
      class="mx-4"
      inset
      dense
    ></v-switch>

    <div v-for="scheduling in visibleSchedules" :key="scheduling.id">
      <LeadSchedulingCard
        :scheduling="scheduling"
        flat
        @click="handleClickShowScheduling(scheduling)"
        @click:failure="handleDefineContactAttempt(scheduling)"
        @click:cancel="handleClickCancelScheduling(scheduling)"
        @click:schedule="handleClickSchedule(scheduling)"
        @click:refuse="handleClickRefuseScheduling(scheduling)"
        @click:logs="handleClickShowLogs(scheduling)"
      />

      <v-divider></v-divider>
    </div>

    <NewSchedulingDialog
      ref="new_scheduling_dialog"
      :lead_identifier_code="lead_identifier_code"
      @created="addScheduling"
    />

    <SuccessScheduleDialog
      ref="success_schedule_dialog"
      :lead_identifier_code="lead_identifier_code"
      @scheduled="updateScheduling"
    ></SuccessScheduleDialog>

    <LeadSchedulingDialog
      ref="lead_scheduling_dialog"
      @reload="loadSchedules()"
      :lead_identifier_code="lead_identifier_code"
    ></LeadSchedulingDialog>

    <RefuseSchedulingDialog
      ref="refuse_scheduling_dialog"
      @refused="updateScheduling"
    />

    <CancelSchedulingDialog
      ref="cancel_scheduling_dialog"
      @canceled="updateScheduling"
    />

    <DefineContactAttemptDialog
      ref="define_attempt_dialog"
      :scheduling="scheduling"
      @reload="loadSchedules()"
    ></DefineContactAttemptDialog>

    <LogsDialog ref="logs_dialog" title="Histórico"></LogsDialog>
  </v-card>
</template>

<script>
import { indexByLead, indexLogs } from "@/services/lead_schedules.js";
import NewSchedulingDialog from "./NewSchedulingDialog.vue";
import SuccessScheduleDialog from "./SuccessScheduleDialog.vue";
import DefineContactAttemptDialog from "./DefineContactAttemptDialog.vue";
import LeadSchedulingCard from "./LeadSchedulingCard.vue";
import LeadSchedulingDialog from "./LeadSchedulingDialog.vue";
import RefuseSchedulingDialog from "./RefuseSchedulingDialog.vue";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import CancelSchedulingDialog from "./CancelSchedulingDialog.vue";
import LogsDialog from "../Logs/LogsDialog.vue";

export default {
  components: {
    NewSchedulingDialog,
    SuccessScheduleDialog,
    LeadSchedulingCard,
    LeadSchedulingDialog,
    RefuseSchedulingDialog,
    CancelSchedulingDialog,
    DefineContactAttemptDialog,
    LogsDialog,
  },

  mixins: [ToastsMixin],

  props: {
    lead_identifier_code: String,
  },

  data() {
    return {
      loading: false,
      schedules: [],
      scheduling: [],
      showCanceled: false,
    };
  },

  methods: {
    async loadSchedules() {
      this.loading = true;

      const response = await indexByLead(this.lead_identifier_code);
      this.schedules = response.data;

      this.loading = false;
    },

    handleClickNewScheduling() {
      this.$refs["new_scheduling_dialog"].open();
    },

    handleClickSchedule(scheduling) {
      this.$refs["success_schedule_dialog"].open(scheduling);
    },

    handleDefineContactAttempt(scheduling) {
      this.$refs["define_attempt_dialog"].open(scheduling);
    },

    addScheduling(scheduling) {
      this.schedules.unshift(scheduling);
    },

    updateScheduling(scheduling) {
      this.schedules = this.schedules.map((item) => {
        if (item.id !== scheduling.id) {
          return item;
        }

        return { ...scheduling };
      });
    },

    async handleClickCancelScheduling(scheduling) {
      this.$refs["cancel_scheduling_dialog"].open(scheduling);
    },

    handleClickShowScheduling(scheduling) {
      this.$refs["lead_scheduling_dialog"].open(scheduling);
    },

    handleClickRefuseScheduling(scheduling) {
      this.$refs["refuse_scheduling_dialog"].open(scheduling);
    },

    async handleClickShowLogs(scheduling) {
      const logs = await indexLogs(scheduling.id);

      this.$refs["logs_dialog"].open(logs);
    },
  },

  computed: {
    visibleSchedules() {
      if (this.showCanceled) {
        return this.schedules;
      }

      return this.schedules.filter((scheduling) => {
        return !["canceled", "refused"].includes(scheduling.status);
      });
    },
  },

  created() {
    this.loadSchedules();
  },
};
</script>
