<template>
  <v-card v-bind="$attrs">
    <v-list-item @click="$emit('click')" link>
      <v-list-item-content>
        <v-list-item-title class="mb-2">
          {{ scheduling.scheduling_type.name }}
          <SchedulingStatusChip
            class="ml-2"
            :status="scheduling.status"
            small
            label
          />
        </v-list-item-title>
        <v-list-item-subtitle>
          Próxima tentativa:
          <span
            v-if="scheduling.next_attempt_at"
            v-text="scheduling.next_attempt_at_diff"
            :title="scheduling.next_attempt_at"
          ></span>
          <span v-else>---</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Data marcada:
          {{
            scheduling.date ? `${scheduling.date} ${scheduling.time}` : "---"
          }}
          <span class="mx-2"></span>
          Expira em:
          <span
            v-if="scheduling.expires_at_diff"
            v-text="scheduling.expires_at_diff"
            :title="scheduling.expires_at"
          ></span>
          <span v-else v-text="'---'"></span>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          1ª Tentativa: {{ scheduling.first_attempt_at || "---" }}
          <span class="mx-2"></span> 2ª Tentativa:
          {{ scheduling.second_attempt_at || "---" }}
          <span class="mx-2"></span> 3ª Tentativa:
          {{ scheduling.third_attempt_at || "---" }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions>
      <v-btn
        v-for="action in actions_to_show"
        :key="action.code"
        text
        small
        :color="action.color"
        @click="$emit(`click:${action.code}`)"
      >
        <v-icon left>{{ action.icon }}</v-icon> {{ action.title }}
      </v-btn>

      <v-menu v-if="actions_to_hide.length > 0" offset-x right>
        <template #activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-subheader>AÇÕES</v-subheader>
          <v-list-item
            v-for="action in actions_to_hide"
            :key="action.code"
            @click="$emit(`click:${action.code}`)"
            :class="`${action.color}--text`"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon left :color="action.color">{{ action.icon }}</v-icon>
                {{ action.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>
    <LogsDialog ref="logs" />
  </v-card>
</template>

<script>
import SchedulingStatusChip from "./SchedulingStatusChip.vue";

const CANCEL_ACTION = {
  code: "cancel",
  icon: "mdi-window-close",
  title: "Cancelar",
  color: "red",
  hidden: true,
};

const FAILURE_ACTION = {
  code: "failure",
  icon: "mdi-phone-cancel",
  title: "Insucesso ao contactar",
  color: "warning",
};

const SCHEDULE_ACTION = {
  code: "schedule",
  icon: "mdi-phone",
  title: "Agendar",
  color: "primary",
};

const REFUSE_ACTION = {
  code: "refuse",
  icon: "mdi-hand-back-left",
  title: "Recusado pelo lesado",
  color: "red",
  hidden: true,
};

const SHOW_LOGS = {
  code: "logs",
  icon: "mdi-history",
  title: "Exibir histórico",
  color: "secondary",
  hidden: true,
};

export default {
  components: {
    SchedulingStatusChip,
  },

  props: {
    scheduling: Object,
  },

  data() {
    return {};
  },

  computed: {
    available_actions() {
      switch (this.scheduling.status) {
        case "pending":
          return [
            SCHEDULE_ACTION,
            FAILURE_ACTION,
            REFUSE_ACTION,
            CANCEL_ACTION,
            SHOW_LOGS,
          ];
        case "failure":
          return [SHOW_LOGS];
        case "concluded":
          return [CANCEL_ACTION, SHOW_LOGS];
        case "canceled":
          return [SHOW_LOGS];
        case "refused":
          return [SHOW_LOGS];
        case "letter":
          return [SCHEDULE_ACTION, REFUSE_ACTION, CANCEL_ACTION, SHOW_LOGS];
      }

      return [];
    },

    actions_to_show() {
      return this.available_actions.filter((action) => {
        return !!!action.hidden;
      });
    },

    actions_to_hide() {
      return this.available_actions.filter((action) => {
        return !!action.hidden;
      });
    },
  },
};
</script>
