<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card :disabled="submitting">
      <v-form @submit.prevent="handleSubmit">
        <v-card-title>
          Vídeo Peritagem
          <v-spacer></v-spacer>
          <v-btn @click="handleClose" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div class="subtitle font-weight-bold mb-5 mt-2">Agendamento</div>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.date"
                :error-messages="errors.starts_at"
                type="date"
                label="Data"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.time"
                :error-messages="errors.starts_at"
                type="time"
                label="Hora"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-chip @click="handleSetNow" link>
            <v-icon left>mdi-flash</v-icon>
            Imediato
          </v-chip>

          <div class="subtitle font-weight-bold mb-5 mt-10">Participante</div>
          <v-text-field v-if="isEditing" :value="form.participant.name" label="Participante" outlined disabled></v-text-field>
          <v-select
            v-else
            v-model="form.participant_id"
            @change="handleChangeParticipant"
            :items="lead.participants"
            label="Participante"
            outlined
            item-value="id"
            :error-messages="errors.participant_id"
          >
            <template v-slot:item="{ item }">
              {{ item.contact.name }} ({{ item.type.description }})
            </template>
            <template v-slot:selection="{ item }">
              {{ item.contact.name }}
            </template>
          </v-select>
          <v-combobox
            label="Telemóvel"
            outlined
            :items="participant_phones"
            :search-input.sync="form.phone"
            v-model="form.phone"
            :error-messages="errors.callee_phone"
          ></v-combobox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="handleClose" text> Cancelar </v-btn>
          <v-btn color="primary" type="submit" :loading="submitting">
            Enviar link
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { store, update } from "@/services/video_calls/video_calls";
import {
  dateTimeToTimestamp,
} from "@/tools/date";

export default {
  components: {},

  data() {
    return {
      dialog: false,
      errors: {},
      form: {
        date: null,
        send_invite: 1,
        participant_id: null,
        phone: null,
      },
      timestamp: null,
      send_invite_action: 1,
      submitting: false,
    };
  },
  methods: {
    init(video_call = null) {
      this.dialog = true;
      this.errors = {};

      if (video_call === null) {
        this.resetForm();
        return;
      }

      this.fill(video_call);
      this.timestamp = video_call.starts_at_timestamp;
    },
    fill(video_call) {
      this.form = {
        id: video_call.key,
        date: video_call.starts_at_date,
        time: video_call.starts_at_time,
        phone: video_call.callee_phone,
        participant: video_call.participant,
        participant_id: video_call.participant.id
      };
    },
    handleChangeParticipant(participant_id) {
      if (this.participant_phones.length > 0) {
        this.form.phone = this.participant_phones[0];
      } else {
        this.form.phone = "";
      }
    },
    handleSetNow() {
      const now = new Date();
      const year = now.getFullYear().toString();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");

      this.form.date = `${year}-${month}-${day}`;

      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");

      this.form.time = `${hours}:${minutes}`;
    },
    resetForm() {
      this.form = {
        id: null,
        date: null,
        time: null,
        send_invite: 1,
        participant: null,
        phone: null,
        participant_id: null,
      };
      this.errors = {};
    },
    handleChangePhone(number) {
      this.form.phone = number;
    },
    handleUpdateDate(date) {
      this.form.date = `${date.date} ${date.time}`;
      this.$refs.scheduling_date.close();
    },
    getPayload() {
        return {
          starts_at: dateTimeToTimestamp(this.form.date, this.form.time),
          lead_id: this.lead.id,
          callee_phone: this.form.phone,
          participant_id: this.form.participant_id,
        };
    },
    async handleSubmit() {
      this.submitting = true;
      this.errors = {};
      try {
        const data = this.form.id
          ? await this.update(this.form.id, this.getPayload())
          : await this.createNew(this.getPayload);

        this.$emit("submitted", data);
        this.handleClose();
      } catch (error) {
        console.error(error)
        if (error?.response?.data?.errors) {
          this.errors = error.response.data.errors;
        }
        if (error?.response?.data?.message) {
          this.$store.commit("error", error.response.data.message);
        }
      } finally {
        this.submitting = false;
      }
    },
    async createNew() {
      const { data } = await store(this.getPayload());

      return data;
    },
    async update(id) {
        const { data } = await update(id, this.getPayload());

        return data;
    },
    handleClose() {
      this.dialog = false;
      this.$emit("closed");
    },
  },

  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
    participant_phones() {
      if (!this.form.participant_id) {
        return [];
      }

      const participant = this.lead.participants.find(
        (item) => item.id === this.form.participant_id
      );

      const phones = [];
      if (participant.contact.contact1) {
        phones.push(participant.contact.contact1);
      }
      if (participant.contact.contact2) {
        phones.push(participant.contact.contact2);
      }

      return phones;
    },
    isEditing() {
      return !!this.form.id;
    }
  },
};
</script>
