<template>
<v-card>
    <v-card-title> 
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn @click="refreshEmails()" icon>
            <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn @click="$emit('cancel')" icon>
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text style="heigth: 70vh" v-if="loading">
        <div class="my-10 text-center">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
    </v-card-text>
    <v-card-text style="heigth: 70vh" v-else-if="emails.length == 0">
        <div class="my-10 text-center">
            <v-img src="@/assets/box.svg" contain height="100px" class="mb-2" />
            {{ not_found_text }} <br />
            <v-btn @click="refreshEmails()" color="primary" outlined class="mt-2" small>{{ __('Recarregar', 'schedulings') }}</v-btn>
        </div>
    </v-card-text>
    <v-card-text style="heigth: 70vh" v-else>
        <v-list>
            <div v-for="(email, index) in emails" :key="email.id">
                <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title> 
                            <span class="text--disabled">
                                #{{ index+1 }}
                            </span>
                            <span>
                                {{ formatDateTimeWithDuration(email.created_at) }} 
                            </span>
                        </v-list-item-title>
                        <v-list-item-subtitle> {{__('Autor', 'schedulings') }}: {{ email.created_by.name }} </v-list-item-subtitle>
                        <v-list-item-subtitle> {{ __('Destinatário', 'schedulings') }}: {{ email.email }} </v-list-item-subtitle>
                        <v-list-item-subtitle> Local da Vistoria: {{ risk_location(email) }} </v-list-item-subtitle>
                        <v-list-item-subtitle> 
                            <v-menu v-if="email.attachments.length" open-on-hover bot offset-y>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn v-bind="attrs" v-on="on" icon small>
                                        <v-icon small>mdi-paperclip</v-icon> {{ email.attachments.length }}
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="subtitle-2"> {{ __('Anexos', 'schedulings') }} </v-card-title>
                                    <v-list>
                                        <v-list-item v-for="attachment in email.attachments" :key="attachment.id">
                                            <v-list-item-avatar>
                                                <v-icon>mdi-paperclip</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title> {{ attachment.name }} </v-list-item-title>
                                                <v-list-item-subtitle> {{ attachment.category ? attachment.category.description : __('Sem categoria', 'schedulings') }} </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-menu>
                            <v-menu open-on-hover bot offset-y>
                                <template v-slot:activator="{on, attrs}">
                                    <v-btn v-bind="attrs" v-on="on" icon small v-if="email.observation">
                                        <v-icon small>mdi-text</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="subtitle-2"> {{ __('Observações (Opcional)', 'schedulings') }} </v-card-title>
                                    <v-card-text>
                                        {{ email.observation }}
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon color="grey">mdi-email-check-outline</v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
            </div>
        </v-list>
    </v-card-text>
</v-card>
</template>

<script>
import { formatDateTimeWithDuration } from "@/tools/date";
import i18n from '@/mixins/i18n';

export default {
    name: 'scheduling-unity-list',
    mixins: [i18n],
    data() {
        return {
            formatDateTimeWithDuration,
            emails: [],
            loading: false,
            menu: false,
            solicitation: false,
            notification: false
        };
    },
    methods: {
        async getSolicitations() {
            this.notification = false;
            this.solicitation = true;
            this.emails = [];
            this.loading = true;
            const response = await this.$http.get(`leads/${this.lead.identifier_code}/scheduling_solicitations`);
            this.emails = response.data;
            this.loading = false;
        },
        async getNotifications() {
            this.solicitation = false;
            this.notification = true;
            this.emails = [];
            this.loading = true;
            const response = await this.$http.get(`leads/${this.lead.identifier_code}/scheduling_notifications`);
            this.emails = response.data;
            this.loading = false;
        },

        risk_location(email) {
            const address = email.address_data;
            let street = "N/A";
            if (address?.street) {
              const number = address.number ? ', ' + address.number :  '';
              street = address.street + number;
            }

            const country_area = address?.country_area?.name || "N/A";
            const city = address?.city?.name || "N/A";
            const city_area = address?.city_area?.name || "N/A";

            return `${street} - ${city_area}, ${city}, ${country_area}`;
        },

        async refreshEmails() {
            if (this.solicitation) {
                return this.getSolicitations();
            }

            if (this.notification) {
                return this.getNotifications();
            }
        }
    },
    computed: {
        title() {
            if (this.solicitation) {
                return this.__('Solicitação de Agendamento', 'schedulings');
            }

            if (this.notification) {
                return this.$capitalize(this.__('Notificações de agendamento', 'schedulings'));
            }

            return 'Emails'
        },
        not_found_text() {
            if (this.solicitation) {
                return this.__('Nenhuma solicitação de agendamento encontrada', 'schedulings');
            }

            if (this.notification) {
                return this.__('Nenhuma notificação de agendamento encontrada', 'schedulings');
            }

            return this.__('Nenhum email encontrado', 'schedulings');
        },
        lead: function () {
            return this.$store.getters.getLead;
        },
    }
}
</script>