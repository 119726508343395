<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value="time"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="time"
        @input="$emit('change', $event)"
        prepend-icon="mdi-clock"
        readonly
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
        :error-messages="error_messages"
      ></v-text-field>
    </template>
    <v-time-picker
      v-bind="$attrs"
      v-if="menu"
      :value="time"
      @input="$emit('change', $event)"
      full-width
      @click:minute="$refs.menu.save(time)"
    >
    </v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: "time-picker",
  props: {
    time: {
      default: "",
      type: String,
    },
    error_messages: {
      default: () => [],
      type: Array,
    },
  },
  model: {
    prop: "time",
    event: "change",
  },
  data: () => ({
    menu: false,
  }),
};
</script>