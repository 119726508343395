<template>
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <v-card-title>
        Novo agendamento
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-radio-group
          v-model="form.scheduling_type_id"
          label="Escolha o objetivo"
          :error-messages="errors.scheduling_type_id"
        >
          <v-radio
            v-for="scheduling_type in scheduling_types"
            :key="scheduling_type.id"
            :label="scheduling_type.name"
            :value="scheduling_type.id"
          ></v-radio>
        </v-radio-group>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="submit()" color="primary" block :loading="submitting">
          Adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getSchedulingTypes, store } from "@/services/lead_schedules.js";
import ToastsMixin from "@/mixins/ToastsMixin.vue";

export default {
  props: {
    lead_identifier_code: String,
  },

  mixins: [ToastsMixin],

  data() {
    return {
      dialog: false,
      scheduling_types: [],
      loading: false,
      submitting: false,
      form: {
        scheduling_type_id: null,
      },
      errors: {},
    };
  },

  methods: {
    open() {
      this.dialog = true;
      this.loadTypes();
      this.resetErrors();
    },

    close() {
      this.dialog = false;
    },

    async loadTypes() {
      this.loading = true;

      const response = await getSchedulingTypes();
      this.scheduling_types = response.data;

      this.loading = false;
    },

    resetErrors() {
      this.errors = {};
    },

    async submit() {
      this.submitting = true;
      this.resetErrors();

      try {
        const response = await store({
          ...this.form,
          lead_identifier_code: this.lead_identifier_code,
        });
        this.dialog = false;

        this.$emit("created", response.data);
        this.toast("Um agendamento foi adicionado");
      } catch (error) {
        console.error(error);
        if (error.response?.status === 422) {
          this.errors = error.response.data.errors;
          return;
        }
        this.toastError("Erro ao adicionar um agendamento");
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
