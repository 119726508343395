<template>
  <span v-on="$listeners">
    <v-btn @click="openDialog" v-bind="$attrs" :loading="loading">
      <slot />
    </v-btn>
    <v-dialog v-model="dialog" max-width="650" eager>
      <UnitySchedulingForm
        @cancel="dialog = false"
        @submit="handleSubmit"
        ref="form"
        title="Notificação de agendamento"
      />
      <AlertDialog ref="alertDialog" />
    </v-dialog>
  </span>
</template>

<script>
import UnitySchedulingForm from "@/components/UnitySchedulingForm";
import { store as notifyUnity } from "@/services/unity_scheduling_notifications";
import AlertDialog from "@/components/Alerts/AlertDialog";

export default {
  components: {
    UnitySchedulingForm,
    AlertDialog,
  },

  props: {
    lead: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      dialog: false,
    };
  },
  methods: {
    openDialog() {
      if (!this.lead.default_service.analysis_date) {
        this.$refs.alertDialog.open(
          "Não é possível notificar a unidade sem uma data de peritagem."
        );
        return;
      }

      this.$refs.form.init();
      this.dialog = true;
    },

    async handleSubmit(form) {
      this.$refs.form.loading = true;

      try {
        await notifyUnity(this.lead.identifier_code, form);
        this.dialog = false;

        this.$store.commit("success", "Solicitação enviado para a unidade");
      } catch (error) {
        this.$store.commit(
          "error",
          "Falha ao enviar solicitação de agendamento"
        );
      } finally {
        this.$refs.form.loading = false;
      }
    },
  },
};
</script>
