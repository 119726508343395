<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title>
        Detalhes
        <v-spacer></v-spacer>
        <v-btn @click="handleClose" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-simple-table v-if="video_call">
          <div class="subtitle mx-4 mt-7">Chamada</div>
          <tbody>
            <tr>
              <th width="200px">Key</th>
              <td>
                {{ video_call.key }}
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                <v-chip small label>
                  {{ video_call.status }}
                </v-chip>
              </td>
            </tr>
            <tr>
              <th>Início marcado</th>
              <td>{{ video_call.starts_at }}</td>
            </tr>
            <tr>
              <th>Início real</th>
              <td>{{ video_call.started_at }}</td>
            </tr>
            <tr>
              <th>Término às</th>
              <td>
                {{ video_call.finished_at || "---" }}
              </td>
            </tr>
            <tr>
              <th>Link expira às</th>
              <td>
                {{ video_call.expires_at || "---" }}
              </td>
            </tr>
          </tbody>
          <div class="subtitle mx-4 mt-7">Participante</div>
          <tbody>
            <tr>
              <th>Contacto</th>
              <td>
                {{ video_call.callee_phone || "---" }}
              </td>
            </tr>
          </tbody>
          <div class="subtitle mx-4 mt-7">Convite</div>
          <tbody>
            <tr>
              <th>Enviado às</th>
              <td>
                {{ video_call.invite_sent_at || "Não enviado" }}
              </td>
            </tr>
          </tbody>
          <div class="subtitle mx-4 mt-7">Registo</div>
          <tbody>
            <tr>
              <th>Registado por</th>
              <td>
                {{ video_call.created_by ? video_call.created_by.name : "---" }}
              </td>
            </tr>
            <tr>
              <th>Data de registo</th>
              <td>
                {{ video_call.created_at }}
              </td>
            </tr>
            <tr>
              <th>Última alteração</th>
              <td>
                {{ video_call.updated_at }}
              </td>
            </tr>
          </tbody>
          <tbody></tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      video_call: null,
    };
  },

  methods: {
    init(video_call) {
      this.video_call = video_call;
      this.dialog = true;
    },
    handleClose() {
      this.dialog = false;
      this.$emit("close");
    },
  },
};
</script>
