import Vue from "vue";

export async function indexByLead(lead_identifier_code) {
  const response = await Vue.prototype.$http.get(
    `lead_schedules/lead/${lead_identifier_code}`
  );

  return response.data;
}

export async function store(payload) {
  const response = await Vue.prototype.$http.post(`lead_schedules`, payload);

  return response.data;
}

export async function schedule(id, payload) {
  const response = await Vue.prototype.$http.post(
    `lead_schedules/${id}/schedule`,
    payload
  );

  return response.data;
}

export async function failureToContact(id, payload) {
  const response = await Vue.prototype.$http.post(
    `lead_schedules/${id}/failure_to_contact`,
    payload
  );

  return response.data;
}

export async function failureToContactRetroactive(id, payload) {
  const response = await Vue.prototype.$http.post(
    `lead_schedules/${id}/failure_to_contact_retroactive`,
    payload
  );

  return response.data;
}

export async function unsuccess(id, payload) {
  const response = await Vue.prototype.$http.post(
    `lead_schedules/${id}/unsuccess`,
    payload
  );

  return response.data;
}

export async function cancel(id, payload) {
  const response = await Vue.prototype.$http.post(
    `lead_schedules/${id}/cancel`,
    payload
  );

  return response.data;
}

export async function refuse(id, payload) {
  const response = await Vue.prototype.$http.post(
    `lead_schedules/${id}/refuse`,
    payload
  );

  return response.data;
}

export async function getSchedulingTypes() {
  const response = await Vue.prototype.$http.get(`lead_schedules/types`);

  return response.data;
}

export async function updateFailureToContact(id, payload) {
  const response = await Vue.prototype.$http.put(
    `lead_schedules/${id}`,
    payload
  );

  return response.data.data;
}

export async function showLeadScheduling(lead_identifier_code, id) {
  const response = await Vue.prototype.$http.get(
    `lead_schedules/${id}/lead/${lead_identifier_code}/show_schedule`
  );

  return response.data;
}

export async function indexLogs(scheduling_id) {
  const response = await Vue.prototype.$http.get(
    `lead_schedules/${scheduling_id}/logs`
  );

  return response.data.data;
}
