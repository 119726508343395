import Vue from "vue";

export async function index(leadNumber, params) {
  const response = await Vue.prototype.$http.get(`/leads/${leadNumber}/scheduling_notifications`, {
    params
  });

  return response.data;
}

export async function show(leadNumber, id) {
    const response = await Vue.prototype.$http.get(`/leads/${leadNumber}/scheduling_notifications/${id}`);

    return response.data;
}

export async function store(leadNumber, payload) {
    const response = await Vue.prototype.$http.post(`/leads/${leadNumber}/scheduling_notifications`, payload);

    return response.data;
}