<script>
export default {
  mixins: [],

  components: {},

  props: {},

  data() {
    return {
      validationErrors: {},
    };
  },

  methods: {
    resetErrors() {
      this.validationErrors = {};
    },
    responseHasValidationErrors(response) {
      if (!response) {
        return false;
      }

      if (response.status !== 422) {
        return false;
      }

      return true;
    },
    setValidationErrorsFromResponse(response) {
      if (!this.responseHasValidationErrors(response)) {
        return;
      }

      this.validationErrors = response.data.errors;
    },
  },

  computed: {},
};
</script>
