<template>
    <div>
        <v-card v-if="lead.unity" outlined>
            <v-list two-line>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-hard-hat</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="title"> {{ lead.unity.user.name }} </v-list-item-title>
                        <v-list-item-subtitle> {{ __('Unidade Responsável', 'schedulings') }} </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn icon @click="openUnityDialog">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-card-actions>
              <v-tooltip bottom :disabled="lead.scheduling_participant != null">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      v-on="on"
                      @click="handleRequestScheduling()" 
                      :disabled="!lead.scheduling_participant" 
                      color="primary" 
                      text
                    >
                        {{ __('Pedir Agendamento', 'schedulings') }}
                    </v-btn>
                  </div>
                </template>
                <span>{{ $capitalize($tc("model.risk_location_no_risk_location_scheduling_message")) }}</span>
              </v-tooltip>
              <v-btn @click="handleSeeSolicitations()" color="secondary" text>
                  {{ __('Ver solicitações', 'schedulings') }}
              </v-btn>
            </v-card-actions>
        </v-card>

        <v-alert type="warning" v-else>
            {{ __('Nenhuma unidade atribuída', 'schedulings') }}

            <template v-slot:append>
                <v-btn :to="{ name: 'lead_detail_data' }" exact icon :title="__('Atribuir unidade', 'schedulings')">
                    <v-icon>mdi-account-edit</v-icon>
                </v-btn>
            </template>
        </v-alert>

        <v-dialog v-model="dialog" max-width="650" eager>
            <UnitySchedulingForm 
                :title="$capitalize($tc('model.risk_location_appointment_request_message'))"
                ref="scheduling-form"
                @submit="handleSubmitForm"
                @cancel="dialog = false"
            />
        </v-dialog>

        <v-dialog v-model="list_dialog" max-width="650" scrollable eager>
            <SchedulingUnityList
                ref="scheduling-list"
                @cancel="list_dialog = false"
            />
        </v-dialog>

        <ChangeLeadUnityDialog v-model="unity_dialog" />
    </div>
</template>

<script>
import UnitySchedulingForm from "@/components/UnitySchedulingForm";
import SchedulingUnityList from "./SchedulingUnityList";
import ChangeLeadUnityDialog from '@/components/Lead/ChangeLeadUnityDialog';
import i18n from '@/mixins/i18n';

export default {
    name: 'scheduling-unity-card',
    mixins: [i18n],
    components: { UnitySchedulingForm, SchedulingUnityList, ChangeLeadUnityDialog },
    data() {
        return {
            dialog: false,
            list_dialog: false,
            unity_dialog: false
        };
    },
    methods: {
        openUnityDialog() {
            this.unity_dialog = true;
        },
        async handleSubmitForm(form) {
            this.$refs["scheduling-form"].loading = true;

            try {
                await this.$http.post(`/leads/${this.lead.identifier_code}/scheduling_solicitations`, form);

                this.dialog = false;
                this.$store.commit('success', this.$capitalize(this.$tc('model.risk_location_request_unit_message')));
            } catch(error) {
                if(error.response) {
                    this.$store.commit('error', error.response.data.message);
                } else  {
                    console.error(error);
                    this.$store.commit('error', this.$capitalize(this.$tc('model.risk_location_failed_appointment_message')));
                }
            } finally {
                this.$refs["scheduling-form"].loading = false;
            }
        },
        handleRequestScheduling() {
            this.$refs["scheduling-form"].init();
            this.dialog = true;
        },
        handleSeeSolicitations() {
            this.list_dialog = true;
            this.$refs["scheduling-list"].getSolicitations();
        }
    },
    computed: {
        lead: function () {
            return this.$store.getters.getLead;
        },
    }
}
</script>