<template>
  <v-chip v-text="title" :color="color" dark v-bind="$attrs"></v-chip>
</template>

<script>
export default {
  props: {
    status: String,
  },

  computed: {
    color() {
      switch (this.status) {
        case "pending":
          return "info";
        case "failure":
        case "canceled":
          return "red";
        case "concluded":
          return "green";
        case "letter":
          return "grey";
        case "refused":
          return "red";
        default:
          return "grey";
      }
    },
    title() {
      switch (this.status) {
        case "pending":
          return "Em agendamento";
        case "failure":
          return "Insucesso";
        case "canceled":
          return "Cancelado";
        case "concluded":
          return "Concluído";
        case "letter":
          return "Carta";
        case "refused":
          return "Recusado";
        default:
          return "Desconhecido";
      }
    },
  },
};
</script>
