<template>
  <div>
    <ValidationObserver v-slot="props" ref="analysisObserver">
      <v-form @submit.prevent="handleUpdateAnalysis">
        <v-card class="mt-1" outlined>
          <v-card-title>{{ __("Peritagem", "schedulings") }} </v-card-title>

          <v-card-text>
            <v-list>
              <DateListItem
                ref="analysis_date"
                @update="handleUpdateAnalysis($event)"
                :timestamp="lead.default_service.analysis_date"
                :label="__('Data de início', 'schedulings')"
                icon="mdi-calendar"
                readonly
              />
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-clock</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ __("Justificativa de atraso", "schedulings") }}
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <span
                      :class="{
                        'warning--text': analysis_creation_date_diff > 48,
                      }"
                      >{{ analysis_creation_date_diff }}
                      {{ __("Horas", "schedulings") }}</span
                    >
                    {{ __("Máx 48", "schedulings") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="analysis_creation_date_diff > 48">
                <v-list-item-icon>
                  <v-icon>mdi-text-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{
                      __(
                        "Período entre o recebimento do serviço e a visita",
                        "schedulings"
                      )
                    }}
                  </v-list-item-subtitle>
                  <v-list-item-title
                    v-if="lead.default_service.analysis_delay_justification"
                  >
                    {{ lead.default_service.analysis_delay_justification }}
                  </v-list-item-title>
                  <v-list-item-title v-else class="warning--text">
                    <v-icon color="warning" small>mdi-alert</v-icon>
                    {{ __("Nenhuma justificativa", "schedulings") }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="openAnalysisDelayDialog()" icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-text>
            <v-row dense>
              <template v-if="lead.is_from_eps">
                <v-col md="2" cols="12">
                  <div class="pb-4">
                    <v-subheader class="pl-0">{{
                      __("Companhia", "schedulings")
                    }}</v-subheader>
                    <v-btn
                      @click="handleOpenNotificationDialog()"
                      color="primary"
                      outlined
                      :disabled="props.changed"
                      class="mr-2"
                    >
                      <v-icon left>mdi-cloud-upload</v-icon>
                      {{ __("Notificar", "schedulings") }}
                    </v-btn>
                  </div>
                </v-col>
                <v-divider
                  class="my-5 ml-4 mr-4"
                  v-if="$vuetify.breakpoint.mdAndUp"
                  vertical
                />
              </template>
              <v-col>
                <div class="pb-4">
                  <v-subheader class="pl-0">{{
                    __("Unidade", "schedulings")
                  }}</v-subheader>
                  <v-tooltip
                    bottom
                    :disabled="
                      lead.unity != null && lead.scheduling_participant != null
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <UnitySchedulingNotificationButton
                        v-on="on"
                        color="primary"
                        outlined
                        :disabled="!lead.unity || !lead.scheduling_participant"
                        :lead="lead"
                      >
                        <v-icon left>mdi-account-hard-hat</v-icon>
                        {{ __("Notificar", "schedulings") }}
                      </UnitySchedulingNotificationButton>
                    </template>
                    <span v-if="!lead.unity">{{
                      $capitalize(
                        $tc("model.risk_location_no_unit_select_message")
                      )
                    }}</span>
                    <span v-else-if="!lead.scheduling_participant">{{
                      $capitalize(
                        $tc(
                          "model.risk_location_no_risk_location_scheduling_message"
                        )
                      )
                    }}</span>
                  </v-tooltip>
                  <v-btn
                    class="ml-2"
                    @click="handleSeeNotifications()"
                    color="secondary"
                    outlined
                  >
                    {{ __("Exibir notificações", "schedulings") }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>

      <v-dialog persistent max-width="500" v-model="notification_dialog">
        <ValidationObserver
          ref="analisysNotificationObserver"
          v-slot="{ invalid }"
        >
          <v-form @submit.prevent="handleNotifyAnalysis()">
            <v-card :disabled="submitting_analysis_notification">
              <v-card-title>
                {{ __("Alterar data de início de peritagem", "schedulings") }}
                <v-spacer></v-spacer>
                <v-btn @click="notification_dialog = false" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle>
                {{
                  __(
                    "Notifica a companhia que uma visita foi agendada",
                    "schedulings"
                  )
                }}
              </v-card-subtitle>
              <v-card-text>
                <ValidationProvider
                  name="comment_title"
                  v-slot="{ errors }"
                  rules="required|max:256"
                >
                  <v-text-field
                    v-model="analysis_notification_form.comment_title"
                    :label="
                      __('Título do comentário (companhia)', 'schedulings')
                    "
                    :error-messages="errors"
                    filled
                    name="comment_title"
                  />
                </ValidationProvider>

                <ValidationProvider
                  name="comment"
                  v-slot="{ errors }"
                  rules="required|max:512"
                >
                  <v-textarea
                    v-model="analysis_notification_form.comment"
                    :label="__('Comentário (companhia)', 'schedulings')"
                    filled
                    counter="512"
                    :error-messages="errors"
                    name="comment"
                  />
                </ValidationProvider>

                <ValidationProvider
                  name="justification"
                  v-slot="{ errors }"
                  rules="required|max:255"
                >
                  <v-textarea
                    name="justification"
                    v-model="analysis_notification_form.justification"
                    :label="__('Justificativa (Ação EPS)', 'schedulings')"
                    filled
                    counter="255"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="notification_dialog = false"
                  text
                  color="primary"
                >
                  {{ __("Cancelar", "schedulings") }}
                </v-btn>
                <v-btn
                  type="submit"
                  color="primary"
                  :loading="submitting_analysis_notification"
                  :disabled="invalid"
                >
                  {{ __("Enviar", "schedulings") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </ValidationObserver>
      </v-dialog>
    </ValidationObserver>

    <AnalysisDelayDialog
      @submit="handleSubmitAnalysisDelayJustification"
      ref="analysisDelayDialog"
    />

    <v-dialog v-model="list_dialog" max-width="650" scrollable eager>
      <SchedulingUnityList
        ref="scheduling-list"
        @cancel="list_dialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import DateListItem from "@/components/DateListItem";
import { getMessageSugestionToCompany } from "@/services/scheduling";
import { sendAnalysisDate } from "@/services/eps";
import { update as updateService } from "@/services/services";

import UnitySchedulingNotificationButton from "@/components/Lead/Scheduling/UnitySchedulingNotificationButton";
import AnalysisDelayDialog from "@/components/Lead/AnalysisDelayDialog";
import SchedulingUnityList from "./SchedulingUnityList";
import i18n from "@/mixins/i18n";

export default {
  name: "scheduling-analysis-card",
  mixins: [i18n],
  components: {
    UnitySchedulingNotificationButton,
    AnalysisDelayDialog,
    SchedulingUnityList,
    DateListItem,
  },
  data() {
    return {
      notification_dialog: false,
      list_dialog: false,
      analysis_initial_date: null,
      analysis_start_time: null,
      analysis_loading: false,

      submitting_analysis_notification: false,

      analysis_notification_form: {},
    };
  },
  methods: {
    async handleUpdateAnalysis(dateTime) {
      const fullDate = `${dateTime.date} ${dateTime.time}`;
      this.analysis_loading = true;
      const analysis_delay_justification =
        this.calcAnalysisCreationDateDiff(this.lead.creation_date, fullDate) <=
        48
          ? ""
          : this.lead.default_service.analysis_delay_justification;

      try {
        const payload = {
          analysis_date: fullDate,
          analysis_end_date: fullDate,
          analysis_delay_justification,
        };

        const response = await updateService(
          payload,
          this.lead.default_service.id
        );

        this.$store.commit("setLead", {
          ...this.lead,
          default_service: {
            ...this.lead.default_service,
            analysis_date: response.analysis_date,
            analysis_end_date: response.analysis_end_date,
            analysis_delay_justification,
          },
        });

        this.$store.commit("sendMessage", {
          text: this.__("Atualizando as datas de peritagem", "schedulings"),
          color: "green",
        });

        this.$refs.analysisObserver.reset();

        if (this.analysis_creation_date_diff > 48) {
          this.$refs.analysisDelayDialog.open(
            this.lead.default_service.analysis_delay_justification
          );
        }
      } catch (error) {
        this.$store.commit("error", error);
      } finally {
        this.analysis_loading = false;
      }
    },
    async handleOpenNotificationDialog() {
      const leadNumber = this.$route.params.lead_number;
      const response = await getMessageSugestionToCompany(leadNumber);
      this.analysis_notification_form = {
        comment_title: response.comment_title,
        comment: response.comment,
        justification: response.justification,
      };
      this.notification_dialog = true;

      if (this.$refs.analisysNotificationObserver) {
        this.$refs.analisysNotificationObserver.reset();
      }
    },
    handleSeeNotifications() {
      this.list_dialog = true;
      this.$refs["scheduling-list"].getNotifications();
    },
    async handleNotifyAnalysis() {
      this.submitting_analysis_notification = true;
      try {
        const response = await sendAnalysisDate(
          this.lead.identifier_code,
          this.analysis_notification_form
        );

        if (response.status === 200) {
          this.$store.commit("alert", response.data.message);
        } else if (response.status === 201) {
          this.$store.commit(
            "success",
            'Notificação de "Alterar data de início da peritagem" criada'
          );
        }

        this.notification_dialog = false;
      } catch (error) {
        if (error.response.status === 400) {
          this.$store.commit("error", error.response.data.message);
        } else if (error.response.status === 422) {
          this.$refs.analisysNotificationObserver.setErrors(
            error.response.data.errors
          );
        } else {
          this.$store.commit(
            "error",
            this.__(
              "Falha ao realizar a ação, tente novamente mais tarde",
              "schedulings"
            )
          );
        }
      } finally {
        this.submitting_analysis_notification = false;
      }
    },
    openAnalysisDelayDialog() {
      this.$refs.analysisDelayDialog.open(
        this.lead.default_service.analysis_delay_justification
      );
    },
    async handleSubmitAnalysisDelayJustification(justification) {
      try {
        await updateService(
          {
            analysis_delay_justification: justification,
          },
          this.lead.default_service.id
        );
        this.lead.default_service.analysis_delay_justification = justification;
        this.$store.commit("setLead", {
          ...this.lead,
          default_service: {
            ...this.lead.default_service,
            analysis_delay_justification: justification,
          },
        });
      } catch (error) {
        this.$refs.analysisDelayDialog.open(
          justification,
          this.__(
            "Falha ao salvar justificativa, tente novamente ou notifique o suporte",
            "schedulings"
          )
        );
      }
    },
    calcAnalysisCreationDateDiff(analysis_date, creation_date) {
      if (!analysis_date) return 0;
      if (!creation_date) return 0;

      const analysis_moment = this.$moment(analysis_date);
      const creation_moment = this.$moment(creation_date);

      return analysis_moment.diff(creation_moment, "hours");
    },
    getDateAnalysisDate(timestamp) {
      if (!timestamp) return "";
      const parts = timestamp.split(" ");

      return parts[0] || "";
    },
    getTimeAnalysisDate(timestamp) {
      if (!timestamp) return "";
      const parts = timestamp.split(" ");

      return parts[1] || "";
    },
  },
  computed: {
    lead: function () {
      return this.$store.getters.getLead;
    },

    analysis_creation_date_diff() {
      return this.calcAnalysisCreationDateDiff(
        this.lead.default_service.analysis_date,
        this.lead.creation_date
      );
    },
  },
};
</script>
