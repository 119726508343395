<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-form @submit.prevent="submit">
      <v-card>
        <v-card-title>
          Registrar tentativa de contacto manualmente
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.date"
                :error-messages="validationErrors.date"
                type="date"
                label="Data"
                filled
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.time"
                :error-messages="validationErrors.time"
                type="time"
                label="Hora"
                filled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-alert dense type="warning" text outlined>
            O registro manual não enviará SMS
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="ml-2">
            <v-btn @click="close()" color="primary" text>Cancelar</v-btn>
          </div>
          <div class="ml-2">
            <v-btn type="submit" color="primary">Registrar</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import ValidationErrorsMixin from "@/mixins/ValidationErrorsMixin.vue";
import { failureToContactRetroactive } from "@/services/lead_schedules.js";
export default {
  data() {
    return {
      dialog: false,
      schedules: [],
      form: {
        date: null,
        time: null,
      },
    };
  },
  mixins: [ToastsMixin, ValidationErrorsMixin],
  props: {
    scheduling: Object,
  },
  methods: {
    open() {
      this.dialog = true;
      this.resetForm();
    },
    close() {
      this.dialog = false;
      this.$emit("close");
    },
    reload() {
      this.$emit("reload");
    },
    resetForm() {
      this.form = {
        date: null,
        time: null,
      };
    },
    updateScheduling(scheduling) {
      this.schedules = this.schedules.map((item) => {
        if (item.id !== scheduling.id) {
          return item;
        }

        return { ...scheduling };
      });
    },

    async submit() {
      const data = {
        ...this.form,
        schedulingId: this.scheduling.id,
      };

      try {
        await this.handleFailureToContactRetroactive(data);
      } catch (error) {
        this.toastError("Erro ao enviar formulário:", error);
      }
    },

    async handleFailureToContactRetroactive(scheduling) {
      try {
        const response = await failureToContactRetroactive(
          scheduling.schedulingId,
          {
            date: scheduling.date,
            time: scheduling.time,
          }
        );

        this.updateScheduling(response.data);

        if (response.data.status === "letter") {
          this.toast(
            "Tentativa de contactar registada. O limite de tentativas foi alcançado"
          );
        } else {
          this.toast("Tentativa de contactar registada");
        }
        this.$store.dispatch("refreshLead");
        this.close();
        this.reload();
      } catch (error) {
        if (error.response?.status === 400) {
          this.toastError(error.response?.data?.message);
          return;
        }

        this.toastError(
          "Ocorreu uma falha ao registar a tentativa de contacto"
        );
      }
    },
  },
};
</script>
