<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-timeline dense>
          <v-timeline-item
            v-for="item in items"
            :key="item.id"
            :icon="item.from_system ? 'mdi-robot' : 'mdi-account'"
            fill-dot
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-subtitle v-if="!item.from_system">
                  <span :title="item.user_email">
                    {{ item.user_name }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-title class="text-wrap">
                  {{ item.action_description }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="caption">
                {{ item.created_at }}
              </v-list-item-action>
            </v-list-item>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  mixins: [],

  components: {},

  props: {
    title: String,
  },

  data() {
    return {
      items: [],
      dialog: false,
    };
  },

  methods: {
    open(items = []) {
      this.items = items;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },

  computed: {},
};
</script>
