<template>
  <div>
    <v-card outlined :loading="scheduling_loading">
      <v-card-title>
        <span>{{ __('Agenda', 'schedulings') }}</span>
        <v-spacer />
        <v-btn
          :loading="scheduling_loading"
          icon
          @click="getSchedulings"
          class="mr-2"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <ScopeProvider scope="leads.schedulings.add">
          <v-btn 
            color="primary" 
            outlined 
            @click="open_scheduling = true" 
            class="mr-2"
          >
            {{ __('Novo agendamento', 'schedulings') }}
          </v-btn>
        </ScopeProvider>
        <v-btn 
          color="primary" 
          outlined 
          @click="toggleView" 
          :title="view_info.title"
        >
          <v-icon>{{ view_info.icon }}</v-icon>
        </v-btn>
      </v-card-title>
      <div v-if="current_view == 'calendar'">
        <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon class="ma-2" @click="$refs.calendar.next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <div class="calendar-month">
          <span>{{ $moment(calendar_date).format("MMMM/YYYY") }}</span>
        </div>
        <v-calendar
          ref="calendar"
          type="month"
          v-model="calendar_date"
          :events="schedulings_events"
          :event-color="(event) => event.color"
          @click:event="({ event }) => event.edit()"
        ></v-calendar>
      </div>
      <div v-else>
        <v-data-table
          :items="schedulings"
          :headers="list_headers"
          hide-default-footer
        >
          <template v-slot:item.date="{ item }">{{
            formatDateTime(item.date, item.time)
          }}</template>
          <template v-slot:item.actions="{ item }">
            <ScopeProvider scope="leads.schedulings.edit">
              <v-btn icon @click="editScheduling(item)">
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </ScopeProvider>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog
      v-model="open_scheduling"
      @click:outside="handleCloseDialog"
      max-width="800px"
    >
      <v-card outlined>
        <v-app-bar flat>
          <v-btn icon @click="previousSchedulingStep" v-if="form_step == 2"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-toolbar-title class="font-weight-medium">{{ __('Novo agendamento', 'schedulings') }} {{ objective_name }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="handleCloseDialog"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-app-bar>
        <SchedulingStepper
          v-model="form"
          :step="form_step"
          :lead="lead"
          @changeStep="form_step = $event"
          @submit="handleSubmitDialog"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { formatDateTime } from "@/tools/date";
import { index as indexSchedulings } from "@/services/scheduling";

import SchedulingStepper from "@/components/Lead/Scheduling/SchedulingStepper";
import ScopeProvider from "@/components/ScopeProvider";

import i18n from '@/mixins/i18n';

export default {
  components: { SchedulingStepper, ScopeProvider },
  mixins: [i18n],
  data: function() {
    return {
      current_view: "calendar",
      scheduling_loading: false,

      //Calendar
      schedulings_events: [],
      calendar_date: new Date().toISOString().substr(0, 10),

      //List
      list_headers: [
        {
          text: this.$capitalize(
            this.__('Objetivo', 'schedulings', 1)
          ),
          value: "objective.description",
        },
        {
          text: this.$capitalize(
            this.__('Tipo', 'schedulings', 1)
          ),
          value: "type.description",
        },
        {
          text: this.__('Data', 'schedulings', 1),
          value: "date",
        },
        {
          text: this.$capitalize(
            this.__('Participante', 'schedulings', 1)
          ),
          value: "participant.contact.name",
        },
        {
          text: this.$capitalize(
            this.__('Unidade', 'schedulings', 1)
          ),
          value: "service_provider.user.name",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ],

      //Dialog
      open_scheduling: false,
      form_step: 1,
      form: {},
    };
  },
  methods: {
    async getSchedulings() {
      this.scheduling_loading = true;

      try {
        const schedulings = await indexSchedulings(
          this.$route.params["lead_number"]
        );
        this.schedulings = schedulings;
        this.schedulings_events = schedulings.map((scheduling) => {
          const date = this.parseDateTime(scheduling.date, scheduling.time);

          return {
            name:
              scheduling.participant?.contact?.name.substring(0, 10).trim() +
              "...",
            start: date.toDate(),
            color: scheduling.type.color,
            timed: true,
            edit: () => this.editScheduling(scheduling),
          };
        });
      } finally {
        this.scheduling_loading = false;
      }
    },
    toggleView() {
      this.current_view = this.current_view == "calendar" ? "list" : "calendar";
    },
    formatDateTime(date, time) {
      return formatDateTime(this.parseDateTime(date, time));
    },
    parseDateTime(date, time) {
      return this.$moment(date + " " + time);
    },

    //Dialog
    handleSubmitDialog() {
      this.getSchedulings();
      this.handleCloseDialog();
    },
    handleCloseDialog() {
      this.open_scheduling = false;
      this.form_step = 1;
      this.form = {};
    },
    previousSchedulingStep() {
      this.form_step = 1;
      this.form.type_id = null;
    },
    editScheduling(scheduling) {
      this.form = {
        ...scheduling,
      };
      this.form_step = 2;
      this.open_scheduling = true;
    },
  },
  computed: {
    schedulings: {
      get() {
        return this.$store.getters["schedulings/getLeadSchedulings"];
      },
      set(value) {
        this.$store.commit("schedulings/setLeadSchedulings", value);
      },
    },
    lead() {
      return this.$store.getters.getLead;
    },
    objective_name() {
      return this.form.objective?.id
        ? " - " + this.form.objective.description
        : "";
    },
    view_info() {
      if (this.current_view == "calendar") {
        return {
          icon: "mdi-format-list-bulleted",
          title: this.__('Trocar para visualização em lista', 'schedulings'),
        };
      } else {
        return {
          icon: "mdi-calendar",
          title: this.__('Trocar para visualização em calendário', 'schedulings'),
        };
      }
    },
  },
  created() {
    this.getSchedulings();
  },
};
</script>

<style>
.calendar-month {
  font-size: 17px;
  display: inline;
  margin-left: 5px;
  margin-right: 5px;
}
</style>