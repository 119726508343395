<template>
  <v-dialog v-model="dialog" width="600">
    <v-form @submit.prevent="submit()">
      <v-card :disabled="submitting">
        <v-card-title>
          Agendar {{ scheduling.scheduling_type.name }}
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.date"
                :error-messages="validationErrors.date"
                type="date"
                label="Data"
                filled
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="form.time"
                :error-messages="validationErrors.time"
                type="time"
                label="Hora"
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="scheduling.need_expected_end_date" dense>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.expected_end_date"
                :error-messages="validationErrors.expected_end_date"
                type="date"
                label="Data de conclusão prevista"
                filled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-alert
            v-model="showAlert"
            v-text="alert"
            type="warning"
            text
            outlined
          ></v-alert>

          <v-row v-show="showJustification">
            <v-col>
              <v-chip
                v-for="justification in justifications"
                :key="justification.label"
                small
                @click="form.justification = justification.text"
              >
                <v-icon left small>mdi-lightning-bolt</v-icon>
                {{ justification.label }}
              </v-chip>
              <v-textarea
                v-model="form.justification"
                :error-messages="validationErrors.justification"
                class="mt-1"
                label="Justificativa"
                filled
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" text> Cancelar </v-btn>
          <v-btn type="submit" color="primary" :loading="submitting">
            Agendar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { schedule } from "@/services/lead_schedules.js";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import ValidationErrorsMixin from "@/mixins/ValidationErrorsMixin.vue";

export default {
  mixins: [ToastsMixin, ValidationErrorsMixin],

  data() {
    return {
      dialog: false,
      scheduling: null,
      submitting: false,
      form: {
        date: null,
        time: null,
        justification: null,
        expected_end_date: null,
      },
      alert: null,
      showJustification: false,
      showAlert: false,
      justifications: [
        {
          label: "Disponibilidade segurado",
          text: "Visita agendada consoante a disponibilidade do segurado",
        },
        {
          label: "Disponibilidade prestador",
          text: "Visita agendada consoante a disponibilidade do prestador",
        },
      ],
    };
  },

  methods: {
    close() {
      this.dialog = false;
    },

    open(scheduling) {
      this.scheduling = scheduling;
      this.dialog = true;
      this.resetForm();
    },

    resetForm() {
      this.form = {
        date: null,
        time: null,
        justification: null,
        expected_end_date: null,
      };
      this.resetErrors();
    },

    async submit() {
      this.submitting = true;
      this.showAlert = false;
      this.showJustification = false;
      this.resetErrors();

      try {
        const response = await schedule(this.scheduling.id, {
          ...this.form,
          expected_end_date: this.scheduling.need_expected_end_date
            ? this.form.expected_end_date
            : undefined,
        });
        this.$emit("scheduled", response.data);
        this.close();
        this.toast("Agendamento concluído");
      } catch (error) {
        if (this.responseHasValidationErrors(error.response)) {
          this.setValidationErrorsFromResponse(error.response);
          return;
        }

        if (error.response.status === 400) {
          this.showAlert = true;
          this.alert = error.response.data.message;

          if (error.response.data.code === "missing_justification") {
            this.showJustification = true;
            this.form.justification = this.justifications[0].text;
          }

          return;
        }

        this.toastError("Falha ao agendar, tente novamente");
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
