<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="counterMode ? '' : formated_data"
        @input="$emit('change', $event)"
        readonly
        :prepend-icon="prependIcon"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
        :error-messages="error_messages"
      >
      <template v-slot:append>
        <v-avatar size="20" :color="counterColor" v-if="date.length > 0 && counterMode">
          <span class="white--text text-caption">{{ date.length }}</span>
        </v-avatar>
        <v-icon v-else right>mdi-calendar</v-icon>
      </template>
    </v-text-field>
    </template>
    <v-date-picker
      :value="date"
      @input="$emit('change', $event)"
      no-title
      scrollable
      v-bind="$attrs"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)"> OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { formatDate } from "@/tools/date";

export default {
  name: "date-picker",
  props: {
    date: {
      default: null,
      type: [String, Array],
    },
    error_messages: {
      default: () => [],
      type: Array,
    },
    prependIcon: {
      type: String,
      default: "mdi-calendar"
    },
    counterMode: {
      type: Boolean,
      default: false
    },
    counterColor: {
      type: String,
      default: 'primary'
    }
  },
  model: {
    prop: "date",
    event: "change",
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    formated_data: function () {
      if (this.$attrs.hasOwnProperty('range')) {
        if (this.date.length > 0)
          return `${formatDate(this.date[0])} ${this.date[1] ? ' - ' + formatDate(this.date[1]) : ''}`;

        return '';
      }
      
      return formatDate(this.date);
    },
  },
};
</script>