import Vue from "vue";


export async function indexContactAttempt(params) {
  const response = await Vue.prototype.$http.get("/contact_attempts", {
    params
  });

  return response.data;
}

export async function storeContactAttempt(payload) {
  const response = await Vue.prototype.$http.post("/contact_attempts", payload);

  return response.data;
}

export async function destroyContactAttempt(id) {
  const response = await Vue.prototype.$http.delete("/contact_attempts/" + id);

  return response.data;
}

export async function resendMessages(participant_insured_id, payload) {
  const response = await Vue.prototype.$http.post(`/contact_attempts/${participant_insured_id}/resend_messages`, payload);

  return response.data;
}