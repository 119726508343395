<template>
  <v-card outlined :loading="loading">
    <v-card-title>
      Vídeo peritagens
      <v-btn @click="getVideoCalls()" :disabled="loading" icon>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="handleSchedule" color="primary"> Marcar </v-btn>
      <v-btn @click="toggleShowAll" icon>
        <v-icon v-if="!showAll">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-chevron-up</v-icon>
      </v-btn>
    </v-card-title>

    <v-tabs v-model="filter">
      <v-tab> Todas </v-tab>
      <v-tab> Ativas </v-tab>
      <v-tab> Encerradas </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-list>
      <div
        v-for="(video_call, index) in limited_video_calls"
        :key="video_call.id"
      >
        <v-divider v-if="index > 0" />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ video_call.participant.name }} • {{ video_call.starts_at }}
              <v-chip
                label
                small
                class="ml-2"
                :color="video_call.is_active ? 'info' : ''"
              >
                {{ video_call.status }}
              </v-chip>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <div>
              <v-btn
                @click="handleFinish(video_call)"
                color="red"
                v-if="video_call.is_active && video_call.is_started"
                outlined
                class="mx-2"
              >
                <v-icon left> mdi-phone-hangup </v-icon> Encerrar
              </v-btn>
              <v-btn
                @click="handleFinish(video_call)"
                color="red"
                v-else-if="video_call.is_active"
                outlined
                class="mx-2"
              >
                <v-icon left> mdi-close </v-icon> Cancelar
              </v-btn>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="handleShowDetails(video_call)">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon left>mdi-magnify</v-icon> Ver detalhes
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="handleUpdateCall(video_call)" link>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon left>mdi-pencil</v-icon> Alterar
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="handleCopyParticipantsLink(video_call)">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon left>mdi-content-copy</v-icon> Copiar link do
                        participante
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="handleResendParticipantsLink(video_call)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon left>mdi-message-processing</v-icon> Reenviar
                        SMS
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- <v-list-item link>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon left>mdi-delete</v-icon> Excluir
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </div>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list>

    <div v-if="filtered_video_calls.length > 3">
      <v-btn
        @click="toggleShowAll"
        v-if="!showAll"
        small
        text
        color="primary"
        block
      >
        Mostrar todos ({{ filtered_video_calls.length }} itens)
        <v-icon small>mdi-chevron-down</v-icon>
      </v-btn>
      <v-btn @click="toggleShowAll" v-else small text color="primary" block>
        Mostrar menos
        <v-icon small>mdi-chevron-up</v-icon>
      </v-btn>
    </div>

    <VideoCallDetailsDialog ref="dialog" />
    <VideoCallsFormDialog ref="form" @submitted="handleFormSubmitted" />
  </v-card>
</template>

<script>
import {
  indexForLead,
  resend_link,
  finish,
} from "@/services/video_calls/video_calls";
import VideoCallDetailsDialog from "@/components/video_calls/VideoCallDetailsDialog";
import VideoCallsFormDialog from "@/components/video_calls/VideoCallsFormDialog";

export default {
  components: {
    VideoCallDetailsDialog,
    VideoCallsFormDialog,
  },

  data() {
    return {
      video_calls: [],
      loading: false,
      filter: "all",
      showAll: true,
    };
  },

  methods: {
    handleFormSubmitted() {
      this.getVideoCalls();
    },
    handleSchedule() {
      this.$refs.form.init();
    },
    handleUpdateCall(video_call) {
      this.$refs.form.init(video_call);
    },
    handleCopyParticipantsLink(video_call) {
      navigator.clipboard.writeText(video_call.link);

      this.$store.commit("alert", "Link do participante copiado");
    },
    handleCopyPeritLink(video_call) {
      navigator.clipboard.writeText(video_call.perit_link);

      this.$store.commit("alert", "Link do perito copiado");
    },
    async handleResendParticipantsLink(video_call) {
      await resend_link(video_call.key);

      this.$store.commit("alert", "Link reenviado");
    },
    handleShowDetails(video_call) {
      this.$refs.dialog.init(video_call);
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    handleGoToPeritVideoCall(video_call) {
      const route = this.$router.resolve({
        name: "video_call_perit_page",
        params: { key: video_call.key },
      });

      window.open(route.href, "_blank");
    },
    async handleFinish(video_call) {
      try {
        const { data } = await finish(video_call.key);

        this.video_calls = this.video_calls.map((item) => {
          if (item.key === video_call.key) {
            return data;
          }

          return item;
        });
      } catch (error) {
        if (error.response?.data?.message) {
          this.$store.commit("error", error.response.data.message);
        }
      }
    },

    async getVideoCalls() {
      this.loading = true;

      try {
        const { data } = await indexForLead(this.$route.params.lead_number);

        this.video_calls = data;
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    filtered_video_calls() {
      let video_calls = [];

      switch (this.filter) {
        case 0:
          video_calls = this.video_calls;
          break;
        case 1:
          video_calls = this.video_calls.filter(
            (call) => call.is_finished === false
          );
          break;
        case 2:
          video_calls = this.video_calls.filter(
            (call) => call.is_finished === true
          );
          break;
      }

      return video_calls;
    },

    limited_video_calls() {
      if (!this.showAll) {
        return this.filtered_video_calls.slice(0, 3);
      }

      return this.filtered_video_calls;
    },
  },

  created() {
    this.getVideoCalls();
  },
};
</script>
