<template>
  <v-dialog 
    :value="value" 
    @change="handleChange" 
    @click:outside="handleClose"
    width="500px"
    scrollable
  >
    <v-card flat>
      <v-app-bar flat>
        <v-toolbar-title>
          {{ dialogTitle }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text style="height: 500px;">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-list>
              <v-subheader class="px-0">{{ $capitalize($tc("model.risk_location_participants_addresses_title")) }}</v-subheader>
              <v-list-item v-if="participants.length === 0">
                <v-list-item-avatar color="grey lighten-2">
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ $capitalize($tc('model.risk_location_no_contact_address')) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item-group v-model="participant_id" mandatory>
                <v-list-item
                  v-for="participant in localParticipants"
                  :key="participant.id"
                  :value="participant.id"
                  v-slot="{ active }"
                >
                  <v-list-item-action>
                    <v-icon color="primary">
                      {{  active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ participant.contact.name }} ({{ participant.type.description }})</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-icon small class="pr-1">mdi-phone</v-icon>
                      <span>{{ formatContact(participant.contact)}}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="participant.risk_location != null">
                      <v-icon small class="pr-1">mdi-map-marker</v-icon>
                      <a class="link" @click="selectAddress(participant)">{{ formatAddress(participant.risk_location) }}</a>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      <v-icon small class="pr-1">mdi-map-marker</v-icon>
                      <a class="link" @click="selectAddress(participant, true)">{{ $capitalize($tc("model.risk_location_new_risk_location")) }}</a>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>
          <v-tab-item>
            <v-list>
              <v-row dense>
                <v-col cols="12" sm="8">
                  <v-subheader class="px-0">
                    <v-icon small left @click.prevent.stop="goBackToParticipant">mdi-arrow-left</v-icon>
                    <a class="link" @click.prevent.stop="goBackToParticipant" v-if="chosenParticipant.id">
                      {{ $capitalize($tc("model.risk_location_come_back_button")) }}
                    </a>
                  </v-subheader>
                </v-col>
                <v-col cols="12" sm="4" align-self="center">
                  <v-btn small text color="primary" @click.prevent.stop="newAddress">{{ $capitalize($tc("model.risk_location_new_address")) }}<v-icon small>mdi-plus</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-subheader class="px-0" v-if="chosenParticipant.id">
                {{ truncateParticipantName(chosenParticipant.contact.name) }} ({{ chosenParticipant.type.description }})
              </v-subheader>
              <v-list-item v-if="contact_addresses.length === 0">
                <v-list-item-avatar color="grey lighten-2">
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ $capitalize($tc('model.risk_location_no_contact_address')) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item-group v-model="address_parent_id" mandatory>
                <v-list-item
                  v-for="address in contact_addresses"
                  :key="address.id"
                  :value="address.id"
                  v-slot="{ active }"
                >
                  <v-list-item-action>
                    <v-icon color="primary">
                      {{  active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ street(address) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ __('Distrito', 'risk_location') }}:    
                      {{ country_area(address) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ __('Concelho', 'risk_location') }}:     
                      {{ city(address) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ __('Freguesia', 'risk_location') }}:     
                      {{ city_area(address) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click.prevent.stop="editAddress(address)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>
          <v-tab-item eager>
            <v-subheader class="px-0">
              <v-icon small left @click.prevent.stop="goBackToAddresses">mdi-arrow-left</v-icon>
              <a class="link" @click.prevent.stop="goBackToAddresses">
                {{ $capitalize($tc("model.risk_location_come_back_button")) }}
              </a>
            </v-subheader>
            <v-subheader class="px-0" v-if="chosenParticipant.id">
              {{ truncateParticipantName(chosenParticipant.contact.name) }} ({{ chosenParticipant.type.description }})
            </v-subheader>
            <risk-location-address-form ref="riskLocationForm" v-model="address_form" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions v-if="tab === 0 || tab === 1">
        <v-spacer />
        <v-btn @click="handleClose" text>
          {{ __('Cancelar', 'leads') }}
        </v-btn> 
        <v-btn 
          @click="confirmAddress" 
          color="primary" 
          :disabled="participants.length === 0 || chosenParticipant.risk_location == null || chosenParticipant.id == null"
        >
          {{ $capitalize($tc('model.risk_location_confirm_button')) }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer />
        <v-btn @click="handleClose" text>
          {{ __('Cancelar', 'leads') }}
        </v-btn> 
        <v-btn 
          @click="saveAddress" 
          color="primary"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { saveAddress } from '@/services/contact';
import RiskLocationAddressForm from '@/components/Lead/RiskLocationAddressForm';
import i18n from '@/mixins/i18n';

export default {
  name: "select-scheduling-participant-dialog",
  mixins: [i18n],
  components: {
    RiskLocationAddressForm
  },
  props: {
    value: {
      type: Boolean
    },
    participants: {
      type: Array
    },
    participant: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      participant_id: null,
      chosenParticipant: {},
      localParticipants: [],
      tab: 0,
      address_parent_id: null,
      address_form: {},
      risk_location_ref: null,
      saving_address: false,
    };
  },
  computed: {
    contact_addresses() {
      return this.chosenParticipant?.contact?.addresses || [];
    },
    dialogTitle() {
      if (this.tab === 0) {
        return this.$capitalize(this.$tc('model.risk_location_select_participant'));
      }

      if (this.tab === 1) {
        return this.$capitalize(this.$tc('model.risk_location_select_risk_location_title'));
      }

      return this.address_form.id ? this.$capitalize(this.$tc('model.risk_location_edit_address')) : this.$capitalize(this.$tc('model.risk_location_new_address'));
    }
  },
  methods: {
    selectAddress(participant) {
      this.participant_id = participant.id;
      this.address_parent_id = participant?.risk_location?.parent_id;
      this.tab = 1;
    },
    editAddress(address) {
      this.tab = 2;
      this.$refs.riskLocationForm.fillForm(address);
    },
    newAddress() {
      this.tab = 2;
      this.$refs.riskLocationForm.resetForm();
    },
    async saveAddress() {
      const validation = await this.$refs.riskLocationForm.validateForm();
      if (!validation) {
        return;
      }

      this.saving_address = true;
      try {
        const response = await saveAddress(this.chosenParticipant.contact?.id, {
          ...this.address_form,
          country_area_id: this.address_form?.country_area?.id,
          city_id: this.address_form?.city?.id,
          city_area_id: this.address_form?.city_area?.id
        });
        this.chosenParticipant.contact = response.contact;
        this.tab = 1;

        // If the address is the same as the risk location, update the risk location
        if (this.address_parent_id == response.address.id) {
          this.setRiskLocationOnChosenParticipant(response.address);
          this.$listeners.updateParticipant(this.chosenParticipant);
        }
      } catch(error) {
        this.$store.commit("sendMessage", {
            text: this.$capitalize(this.$tc('model.risk_location_no_save_contact_address')),
            color: "red",
        });
      } finally {
        this.saving_address = false;
      }
    },
    goBackToParticipant() {
      this.tab = 0;
    },
    goBackToAddresses() {
      this.tab = 1;
    },
    truncateParticipantName(name) {
      return _.truncate(name, 18);
    },
    setInitialValue() {
      this.participant_id = this.participant?.id;
      this.localParticipants = this.participants;
    },
    handleChange($event) {
      this.$emit("input", $event);
    },
    handleClose() {
      this.$emit("input", false);
    },
    confirmAddress() {
      this.$listeners.updateParticipant(this.chosenParticipant);
      this.handleClose();
    },
    formatContact(contact) {
      const phone = contact.contact1 || contact.contact2;
      return phone || 'N/A';
    },
    formatAddress(address) {
      return `${this.street(address)}, ${this.city_area(address)}, ${this.city(address)} - ${this.country_area(address)}`;
    },
    street(address) {
      if (address?.street) {
        const number = address.number ? ', ' + address.number :  '';
        return address.street + number;
      }

      return "N/A";
    },
    country_area(address) {
      return address?.country_area?.name || "N/A";
    },
    city(address) {
      return address?.city?.name || "N/A";
    },
    city_area(address) {
      return address?.city_area ? address?.city_area?.name : "N/A";
    },
    setRiskLocationOnChosenParticipant(address) {
      this.chosenParticipant = {
        ...this.chosenParticipant,
        risk_location: address ? {
          ...address,
          parent_id: this.address_parent_id
        } : this.chosenParticipant?.risk_location
      }
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.tab = 0;
      }
    },
    participant() {
      this.setInitialValue();
    },
    participant_id(value) {
      if (value)
        this.chosenParticipant = this.participants.find(rec => rec.id == value) || {};

      this.address_parent_id = this.chosenParticipant?.risk_location?.parent_id;
    },
    address_parent_id() {
      const address = this.contact_addresses.find(rec => rec.id == this.address_parent_id);
      this.setRiskLocationOnChosenParticipant(address);
    }
  },
  created() {
    this.setInitialValue();
  }
}
</script>

<style>

</style>