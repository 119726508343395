<template>
  <v-list-item>
    <v-list-item-icon v-if="icon">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-subtitle>
        {{ label }}
      </v-list-item-subtitle>
      <v-list-item-title v-if="humanDate">
        {{ humanDate }} {{ humanTime }}
      </v-list-item-title>
      <v-list-item-title v-else> --- </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action v-if="!readonly">
      <v-menu v-model="menu" :close-on-content-click="false" offset-x right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
        </template>

        <v-card width="250" :disabled="loading">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="handleSubmit">
              <v-subheader>{{ label }}</v-subheader>

              <v-card-text class="mt-0 pt-2">
                <ValidationProvider
                  name="date"
                  :rules="{
                    required: false,
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="form.date"
                    label="Data"
                    type="date"
                    autofocus
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider
                  name="time"
                  :rules="{
                    required: false,
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    @input="handleInputTime"
                    :value="form.time"
                    label="Hora"
                    type="time"
                    step="2"
                    :error-messages="errors"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <div>
                  <v-btn @click="handleClear()" text> Limpar </v-btn>
                </div>
                <v-spacer></v-spacer>
                <div>
                  <v-btn
                    type="submit"
                    color="primary"
                    :loading="loading"
                    :disabled="invalid"
                  >
                    Salvar
                  </v-btn>
                </div>
              </v-card-actions>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { formatDate } from "@/tools/date";
import { readonly } from "vue";

export default {
  props: {
    label: String,
    dateInput: String,
    timeInput: String,
    timestamp: String,
    icon: String,
    readonly: Boolean,
  },

  data() {
    return {
      form: {
        date: "",
        time: "",
      },
      menu: false,
      formatDate,
      loading: false,
    };
  },

  methods: {
    handleClear() {
      this.form = {
        date: "",
        time: "",
      };
    },

    setErrors(errors) {
      this.$refs.form.setErrors({
        date: errors,
      });
    },

    handleSubmit() {
      this.$emit("update", {
        ...this.form,
        time: this.preventNonTime(),
      });
    },

    preventNonTime() {
      if (!this.form.date) {
        return "";
      }

      if (!this.form.time) {
        return "00:00:00";
      }

      return this.form.time;
    },

    formatTime(time) {
      if (!time) return "";

      const parts = time.split(":");
      if (parts.length > 2) {
        return `${parts[0]}:${parts[1]}`;
      }

      return time;
    },

    close() {
      this.menu = false;
    },

    getDateFromTimestamp() {
      if (!this.timestamp) return "";

      const parts = this.timestamp.split(" ");

      return parts[0] || "";
    },

    getTimeFromTimestamp() {
      if (!this.timestamp) return "";

      const parts = this.timestamp.split(" ");

      return parts[1] || "";
    },

    handleInputTime(time) {
      if (!time) this.form.time = "";

      const parts = time.split(":");
      for (let i = 0; i < 3 - parts.length; i++) {
        parts.push("00");
      }

      this.form.time = parts.join(":");
    },
  },

  watch: {
    menu(value) {
      if (value) {
        this.form = {
          date: this.date || "",
          time: this.time || "",
        };
      }
    },
  },

  computed: {
    date() {
      return this.dateInput ? this.dateInput : this.getDateFromTimestamp();
    },
    time() {
      return this.timeInput ? this.timeInput : this.getTimeFromTimestamp();
    },
    humanDate() {
      return formatDate(this.date);
    },

    humanTime() {
      return this.formatTime(this.time);
    },
  },
};
</script>
