<template>
    <div>
        <v-dialog v-model="dialog" max-width="800px" persistent>
            <v-card>
                <ValidationObserver ref="analysisDelayForm" v-slot="{ invalid }">
                <v-form @submit.prevent="submit">
                    <v-card-title>
                        {{ __('Justificativa de atraso', 'schedulings') }}
                        <v-spacer></v-spacer>
                        <v-btn @click="close" icon>
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-alert type="error" v-if="error.length" text>
                            {{ error }}
                        </v-alert>
                        <v-row>
                            <v-col>
                                {{ __('Sugestões', 'schedulings') }}
                                <v-chip small @click="applySugestion(sugestion.text)" v-for="sugestion in sugestions" :key="sugestion.id" class="mr-1">
                                    {{ sugestion.name }}
                                </v-chip>
                            </v-col>
                        </v-row>
                        
                        <ValidationProvider
                            name="justification"
                            rules="required|max:255|min:20"
                            v-slot="{ errors }"
                        >
                            <v-textarea 
                                autofocus
                                v-model="justification"
                                outlined 
                               :placeholder="__('Justificativa de atraso', 'schedulings')"
                                counter="255"
                                :error-messages="errors"
                            ></v-textarea>
                        </ValidationProvider>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="close" text color="primary">{{ __('Cancelar', 'schedulings') }}</v-btn>
                        <v-btn :disabled="invalid" type="submit" color="primary">{{ __('Salvar', 'schedulings') }}</v-btn>
                    </v-card-actions>
                </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    props: {
        visitDate: {
            type: String
        },
    },

    data() {
        return {
            dialog: false,
            justification: "",
            error: "",
            sugestions: [
                {
                    id: 1,
                    name: this.__('Disponibilidade do segurado', 'leads'),
                    text: "Visita agendada consoante a disponibilidade do segurado"
                },
                {
                    id: 2,
                    name: this.__('Disponibilidade do prestador', 'leads'),
                    text: "Visita agendada consoante a disponibilidade do prestador"
                }
            ]
        };
    },

    methods: {
        applySugestion(sugestion) {
            this.justification = sugestion;
        },
        open(justification = "", error = "") {
            this.dialog = true;
            this.justification = justification;
            this.error = error;
            
            if(this.$refs.analysisDelayForm) {
                this.$refs.analysisDelayForm.reset();
            }
        },
        close() {
            this.dialog = false;
        },
        async submit() {
            if(!this.$refs.analysisDelayForm.validate()) 
                return;

            this.$emit('submit', this.justification);
            this.close();
        }
    }
}
</script>