<template>
    <div>
        <v-card v-if="lead.scheduling_participant" outlined>
            <v-card-title class="pb-1">{{ __('Local de vistoria', 'risk_location') }}</v-card-title>
            <v-list two-line>
                <v-list-item>
                  <v-list-item-avatar color="grey lighten-2">
                    <v-icon>mdi-map-marker</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ contact_name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ street }}</v-list-item-subtitle>
                    <v-list-item-subtitle
                      >{{ __('Distrito', 'risk_location') }}:
                      {{ country_area }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      >{{ __('Concelho', 'risk_location') }}:
                      {{ city }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      >{{ __('Freguesia', 'risk_location') }}:
                      {{ city_area }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="dialog = true" :loading="changing_risk_location">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>

        <v-alert type="warning" class="mb-0" v-else>
            {{ __('Nenhum local de vistoria selecionado', 'risk_location') }}

            <template v-slot:append>
                <v-btn @click="dialog = true" icon :loading="changing_risk_location">
                    <v-icon>mdi-map-marker-plus</v-icon>
                </v-btn>
            </template>
        </v-alert>

        <select-scheduling-participant-dialog
          v-model="dialog"
          :participants="participants"
          :participant="lead.scheduling_participant"
          @updateParticipant="updateSchedulingParticipant"
        />
    </div>
</template>

<script>
import SelectSchedulingParticipantDialog from "@/components/Lead/Scheduling/SelectSchedulingParticipantDialog";
import { change_scheduling_participant } from "@/services/lead";
import i18n from '@/mixins/i18n';

export default {
    name: 'scheduling-risk-location-card',
    components: { SelectSchedulingParticipantDialog },
    mixins: [i18n],
    data() {
        return {
            dialog: false,
            changing_risk_location: false
        };
    },
    methods: {
        async updateSchedulingParticipant(participant) {
            this.changing_risk_location = true;
            try {
                const response = await change_scheduling_participant(this.lead?.id, { 
                  participant_id: participant?.id,
                  risk_location: participant.risk_location
                });
                this.$store.commit('setLead', {
                    ...this.lead,
                    current_risk_location: response.risk_location,
                    scheduling_participant_id: response.id,
                    scheduling_participant: response,
                    participants: this.participants.map(p => p.id === response.id ? response : p)
                });
            } catch (error) {
                this.$store.commit("sendMessage", {
                    text: this.__('Não foi possível modificar local de vistoria do agendamento', 'risk_location'),
                    color: "red",
                });
            } finally {
                this.changing_risk_location = false;
            }
        }
    },
    computed: {
        scheduling_risk_location() {
            return this.lead.scheduling_participant.risk_location;
        },
        contact_name() {
          return this.lead.scheduling_participant?.contact?.name;
        },
        street() {
          if (this.scheduling_risk_location && this.scheduling_risk_location?.street) {
            const number = this.scheduling_risk_location?.number ? ", " + this.scheduling_risk_location?.number : "";
            return this.scheduling_risk_location?.street + number;
          }

          return "N/A";
        },
        country_area() {
          return this.scheduling_risk_location?.country_area?.name || "N/A";
        },
        city() {
          return this.scheduling_risk_location?.city?.name || "N/A";
        },
        city_area() {
          return this.scheduling_risk_location?.city_area?.name || "N/A";
        },
        lead: function () {
            return this.$store.getters.getLead;
        },
        participants() {
            return this.lead?.participants
              ?.filter(participant => participant.insured);
        }
    }
}
</script>