<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-form @submit.prevent="submit">
      <v-card>
        <v-card-title>
          Alterar {{ title }}
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.date"
                :error-messages="validationErrors.date"
                type="date"
                label="Data"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.time"
                :error-messages="validationErrors.time"
                type="time"
                label="Hora"
                filled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-alert dense type="warning" text outlined>
            A alteração não enviará SMS
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="mr-2">
            <v-btn type="submit" color="primary">Alterar</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { formatCustomDate, formatCustomTime } from "@/tools/date";
import ValidationErrorsMixin from "@/mixins/ValidationErrorsMixin.vue";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import { updateFailureToContact } from "@/services/lead_schedules.js";

export default {
  mixins: [ToastsMixin, ValidationErrorsMixin],
  data() {
    return {
      dialog: false,
      form: {
        date: "",
        time: "",
      },
    };
  },
  props: {
    title: String,
    date: String,
    scheduling: Object,
    attempt: String,
  },
  watch: {
    title(newTitle) {
      this.form.title = newTitle;
    },
    date(newDate) {
      this.form.date = this.formatToISO(formatCustomDate(newDate));
      this.form.time = formatCustomTime(newDate);
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    reload(leadScheduling) {
      this.$emit("reload", leadScheduling);
    },

    formatToISO(date) {
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },

    async submit() {
      const data = {
        ...this.form,
        schedulingId: this.scheduling.id,
      };

      try {
        await this.handleFailureToContactUpdate(data);
      } catch (error) {
        this.toastError("Erro ao enviar formulário:", error);
      }
    },

    async handleFailureToContactUpdate(scheduling) {
      try {
        const response = await updateFailureToContact(scheduling.schedulingId, {
          date: scheduling.date,
          time: scheduling.time,
          attempt: this.attempt,
        });
        this.$store.dispatch("refreshLead");
        this.toast("Registro Alterado com sucesso");
        this.close();
        this.reload(response);
      } catch (error) {
        if (error.response?.status === 400) {
          this.toastError(error.response?.data?.message);
          return;
        }

        this.toastError(
          "Ocorreu uma falha ao registar a tentativa de contacto"
        );
      }
    },
  },
};
</script>
