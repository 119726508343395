<template>
  <div>
    <v-card-title class="d-flex justify-center">{{ __("Qual o objetivo do agendamento?", 'schedulings') }}</v-card-title>
    <v-card-text class="d-flex justify-center">
      <v-row dense v-if="fetching_scheduling_objectives">
        <v-col cols="12">
          <v-skeleton-loader
          type="image"
          max-height="70"
        ></v-skeleton-loader>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader
          type="image"
          max-height="70"
        ></v-skeleton-loader>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader
          type="image"
          max-height="70"
        ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row dense v-else>
        <v-col cols="12" v-for="objective in scheduling_objectives" :key="objective.id">
          <v-card outlined @click="chooseObjective(objective)" :disabled="editMode && !isSelected(objective)">
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-{{ objective.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">{{ objective.description }}</v-list-item-title>
                  <v-list-item-subtitle v-if="isSelected(objective)">{{ __('Selecionado', 'schedulings') }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon class="align-self-center">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>

            <v-overlay
              absolute
              opacity="0.05"
              :value="isSelected(objective)"
            >
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import i18n from '@/mixins/i18n';

export default {
  props: ["value"],
  mixins: [i18n],
  methods: {
    chooseObjective(objective) {
      this.$emit("input", {
        ...this.value,
        objective: objective ? objective : {},
        type: null 
      });
    },
    isSelected(objective) {
      return this.value.objective?.id == objective.id;
    },
    ...mapActions("scheduling_objectives", ["loadSchedulingObjectives"])
  },
  computed: {
    editMode() {
      return this.value.id != null;
    },
    fetching_scheduling_objectives() {
      return !this.scheduling_objectives.length > 0;
    },
    ...mapState("scheduling_objectives", ["scheduling_objectives"])
  },
  created() {
    this.loadSchedulingObjectives();
  }
}
</script>

<style>

</style>