<template>
<v-form @submit.prevent="submit">
    <v-card :disabled="loading">
        <v-card-title> {{ title }} </v-card-title>
        <v-card-subtitle> 
            {{ __('Envia um email para a unidade com detalhes do processo.', 'schedulings') }}
        </v-card-subtitle>

        <v-card-text class="mt-5">
            <v-text-field outlined :label="__('Destinatário', 'schedulings')" :value="lead.unity ? lead.unity.user.email : null" readonly></v-text-field>

            <v-autocomplete
                outlined
                v-model="form.attachments"
                :label="__('Anexos (Opcional)', 'schedulings')"
                :loading="loading_files" 
                :items="files" 
                item-value="id"
                item-text="name"
                chips
                multiple
            >
                <template v-slot:item="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ item.group || __('Sem categoria', 'schedulings') }}
                        </v-list-item-subtitle>
                    </v-list-item-content> 
                </template>
            </v-autocomplete>
            
            <v-textarea outlined v-model="form.observation" :label="__('Observações (Opcional)', 'schedulings')"></v-textarea>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="$emit('cancel')" color="primary" text> {{ __('Cancelar', 'schedulings') }}  </v-btn>
            <v-btn type="submit" color="primary" :loading="loading"> 
                <v-icon left size="20">mdi-email</v-icon> {{ __('Enviar Solicitação', 'schedulings') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-form>
</template>

<script>
import i18n from '@/mixins/i18n';
export default {
    mixins: [i18n],
    props: {
        title: String
    },

    data() {
        return {
            files: [],
            loading_files: false,
            form: {},
            loading: false
        };
    },
    methods: {
        async getFiles() {
            this.loading_files = true;
            const response = await this.$http.get(
                `/leads/${this.lead.identifier_code}/attachments`,
            );
            this.files = response.data.map(item => {
                return {
                    ...item,
                    group: item.category ? item.category.description : ""
                };
            });
            this.loading_files = false;
        },
        submit() {
            this.$emit('submit', this.form);
        },
        init() {
            this.form = {};
            this.getFiles();
        }
    },
    computed: {
        lead: function () {
            return this.$store.getters.getLead;
        },
    }
}
</script>