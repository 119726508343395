<template>
  <v-form @submit.prevent="save">
    <ValidationObserver ref="form">
      <div>
        <v-btn class="mt-4" v-if="editMode & $vuetify.breakpoint.smAndDown" text color="error" @click="confirmDelete">
          <v-icon>mdi-delete</v-icon>
          {{ __('Remover', 'schedulings') }}
        </v-btn>
        <v-card-title>
          {{ __('Qual o tipo de agendamento?', 'schedulings') }}
          <v-spacer />
          <ScopeProvider scope="leads.schedulings.delete">
            <v-btn v-if="editMode & $vuetify.breakpoint.mdAndUp" text color="error" @click="confirmDelete">
              <v-icon>mdi-delete</v-icon>
              {{ __('Remover', 'schedulings') }}
            </v-btn>
          </ScopeProvider>
        </v-card-title>
        <ValidationProvider name="scheduling.type_id" rules="required" v-slot="{ errors }">
          <v-card-text v-if="errors && errors.length > 0" class="red--text py-0">{{ errors[0] }}</v-card-text>
          <v-card-text>
            <div class="d-flex" v-if="fetching_scheduling_types">
              <v-skeleton-loader
                type="button"
                class="mr-1"
              />
              <v-skeleton-loader
                type="button"
                class="mr-1"
              />
              <v-skeleton-loader
                type="button"
              />
            </div>
            <v-btn-toggle
              :value="value.type_id"
              @change="setSchedulingType"
              mandatory
              v-else
            >
              <template v-for="type in scheduling_types">
                <v-btn
                  :value="type.id" 
                  outlined
                  :disabled="disableNonDefaultSchedulingType(type)"
                  v-if="$vuetify.breakpoint.mdAndUp"
                  :key="'btn' + type.id"
                >
                  <v-icon left>mdi-{{ type.icon }}</v-icon>
                  {{ type.description }}
                </v-btn>

                <v-tooltip bottom :key="type.id" v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :value="type.id" 
                      outlined
                      :disabled="disableNonDefaultSchedulingType(type)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-{{ type.icon }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ type.description }}</span>
                </v-tooltip>
              </template>
            </v-btn-toggle>
          </v-card-text>
        </ValidationProvider>
      </div>
      <div v-if="can_select_participant">
        <v-card-title>{{ __('Qual o participante contatado?', 'schedulings') }}</v-card-title>
        <ValidationProvider name="scheduling.participant_id" rules="required" v-slot="{ errors }" v-if="value.objective.chooses_participant">
          <v-card-text v-if="errors && errors.length > 0" class="red--text py-0">{{ errors[0] }}</v-card-text>
          <v-card-text class="py-0">
            <v-radio-group
              :value="value.participant_id"
              @change="updateAttribute('participant_id', $event)"
              mandatory
              :disabled="editMode"
            >
              <v-list>
                <v-list-item 
                  v-for="participant in sinister_participants"
                  :key="participant.id"
                >
                  <v-list-item-icon>
                    <v-radio :value="participant.id">
                    </v-radio>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ participant.contact.name }} - {{ participant.type.description }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ participant.contact.contact1 + (participant.contact.contact2 
                          ? '-' + participant.contact.contact2
                          : '') 
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-radio-group>
          </v-card-text>
        </ValidationProvider>
      </div>
      <div v-if="can_select_address">
        <v-card-title>{{ __('Qual o local da visita?', 'schedulings') }}</v-card-title>
        <ValidationProvider name="scheduling.address_id" rules="required" v-slot="{ errors }" v-if="value.objective.chooses_participant">
          <v-card-text v-if="errors && errors.length > 0" class="red--text py-0">{{ errors[0] }}</v-card-text>
          <v-card-text class="py-0" v-if="sinister_locations.length > 0">
            <v-radio-group
              :value="value.address_id"
              @change="updateAttribute('address_id', $event)"
              mandatory
              :disabled="editMode"
            >
              <v-list>
                <v-list-item 
                  v-for="location in sinister_locations"
                  :key="location.id"
                >
                  <v-list-item-icon>
                    <v-radio :value="location.id" :disabled="location.id == null">
                    </v-radio>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ getAddressText(location) }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ location.participant.contact.name }} - {{ location.participant.type.description }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ location.participant.contact.contact1 + (location.participant.contact.contact2 
                          ? '-' + location.participant.contact.contact2
                          : '') 
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="location.id == null">
                      <v-alert
                        text
                        dense
                        type="warning"
                        border="left"
                      >
                        {{ __('Participante não possui local de risco cadastrado', 'schedulings') }}
                      </v-alert>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-radio-group>
          </v-card-text>
        </ValidationProvider>
        <v-card-text v-else>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ insured_information.address }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ insured_information.name }} - {{ insured_information.type }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ insured_information.contacts }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
      <div>
        <v-card-title>{{ __('Qual a data do agendamento?', 'schedulings') }}</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col>
              <ValidationProvider name="scheduling.date" rules="required" v-slot="{ errors }">
                <date-picker
                  name="scheduling.date"
                  :label="
                   __('Data', 'schedulings')
                  "
                  :date="value.date"
                  @change="updateAttribute('date', $event)"
                  :error_messages="errors"
                  :min="new Date().toISOString().substr(0, 10)"
                />
              </ValidationProvider>
            </v-col>
            <v-col>
              <ValidationProvider name="scheduling.time" rules="required" v-slot="{ errors }">
                <time-picker
                  name="scheduling.time"
                  :label="
                   __('Hora', 'schedulings')
                  "
                  :time="value.time"
                  @change="updateAttribute('time', $event)"
                  :error_messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-btn 
            class="green mx-1" 
            dark 
            v-if="form_scheduling_type.canonical == 'videoanalysis' && scheduling_type.canonical == 'videoanalysis' && editMode"
            :loading="loading_call"
            @click="initCall"
          >
            <v-icon left dark>mdi-video</v-icon>{{ __('Iniciar peritagem remota', 'schedulings') }}
          </v-btn>
        </v-card-text>
      </div>
      <v-card-text>
        <ValidationProvider name="scheduling.observation" v-slot="{ errors }">
          <v-textarea
            :label="
             __('Observações', 'schedulings')
            "
            filled
            :value="value.observation"
            @input="updateAttribute('observation', $event)"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-card-text>
    </ValidationObserver>

    <v-card-actions>
      <v-spacer />
      <v-btn :disabled="!$store.getters['auth/getScope']('leads.schedulings.edit')" color="primary" text type="submit" :loading="submitting">{{ __('Salvar', 'schedulings') }}</v-btn>
    </v-card-actions>
    
    <confirm-action-dialog
      :confirm_action="confirm_delete"
      :message="
        __('O agendamento será permanentemente excluído', 'schedulings')
      "
      @change="confirm_delete = $event"
      @action="destroy"
    />
  </v-form>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from 'vuex';

import { 
  create as createScheduling, 
  update as updateScheduling, 
  destroy as destroyScheduling 
} from "@/services/scheduling";
import { startRemoteAnalysis } from "@/services/remote_analysis";

import ConfirmActionDialog from "@/components/ConfirmActionDialog";
import DatePicker from "@/components/DatePicker";
import TimePicker from "@/components/TimePicker";
import ScopeProvider from "@/components/ScopeProvider";
import i18n from '@/mixins/i18n';

export default {
  mixins: [i18n],
  props: ["value", "lead", "errors"],
  components: { ConfirmActionDialog, DatePicker, TimePicker, ScopeProvider },
  data: () => ({
    submitting: false,
    confirm_delete: false,
    loading_call: false,
    scheduling: {}
  }),
  methods: {
    updateAttribute(attribute, value) {
      this.$emit("input", {
        ...this.value,
        [attribute]: value
      });    
    },
    getAddressText(address) {
      const address_info = [];
      
      if (address?.street) address_info.push(address?.street);
      if (address?.city_area?.name) address_info.push(address?.city_area?.name);
      if (address?.cityArea?.name) address_info.push(address?.cityArea?.name);

      return address_info.join(', ');    
    },
    setSchedulingType(type) {
      this.updateAttribute('type_id', type);
    },
    confirmDelete() {
      this.confirm_delete = true;
    },
    async destroy() {
      try {
        await destroyScheduling(this.lead.identifier_code, this.value.id);
        this.$listeners.submit();
      } catch (e) {
        this.$store.commit('sendMessage', {
          text: e.response.data.message,
          color: 'red'
        });
      } finally {
        this.confirm_delete = false;
      }
    },
    async save() {
      const validation = await this.$refs.form.validate();
      if (!validation) return;

      this.submitting = true;
      try {
        if (this.editMode) {
          await this.update();
        } else {
          await this.create();
        }
      } catch(e) {
        if (e?.response?.data?.errors)
          this.$refs.form.setErrors(e.response.data.errors);
        
        if (e?.response?.data?.message)
          this.$store.commit('sendMessage', {
            text: e.response.data.message,
            color: 'error'
          });
      } finally {
        this.submitting = false;      
      }
    },
    async create() {
      const response = await createScheduling(this.lead.identifier_code, {
        scheduling: {
          objective_id: this.value.objective?.id,
          type_id: this.value.type_id,
          address_id: this.value.address_id,
          participant_id: this.value.participant_id,
          date: this.value.date,
          time: this.value.time,
          observation: this.value.observation
        }
      });
      this.scheduling = {
        ...response
      };
      this.$store.commit('sendMessage', {
        text: 'Agendamento criado com sucesso',
        color: 'success'
      });
      this.$listeners.submit(); 
    },
    async update() {
      const response = await updateScheduling(this.lead.identifier_code, this.value.id, {
        scheduling: {
          objective_id: this.value.objective?.id,
          type_id: this.value.type_id,
          address_id: this.value.address_id,
          participant_id: this.value.participant_id,
          date: this.value.date,
          time: this.value.time,
          observation: this.value.observation
        }
      });
      this.scheduling = {
        ...response
      };
      this.$store.commit('sendMessage', {
        text: 'Agendamento atualizado com sucesso',
        color: 'success'
      });
      this.$listeners.submit();  
    },
    disableNonDefaultSchedulingType(type) {
      return !this.value.objective.chooses_scheduling_type && this.value.objective.default_scheduling_type.id != type.id;
    },

    async initCall() {
      this.loading_call = true;
      try {
        const response = await startRemoteAnalysis({
          participant_id: this.value.participant_id,
          scheduling_id: this.value.id
        });
        this.$store.commit("setVideoCall", {
          ...this.$store.getters.getVideoCall,
          isActive: true,
          lead_number: this.lead.identifier_code,
          ...response
        });
        localStorage.setItem('video_call', JSON.stringify(this.$store.getters.getVideoCall));

        window.open(this.$router.resolve({ name: 'unity_video_call' }).href, '_blank');
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: "Falha ao iniciar chamada de vídeo, tente novamente.",
          color: "red",
        });
      } finally {
        this.loading_call = false;
      }
    },
    
    ...mapActions("scheduling_types", ["loadSchedulingTypes"])
  },
  computed: {
    fetching_scheduling_types() {
      return !this.scheduling_types.length > 0;
    },
    editMode() {
      return this.value.id != null;
    },
    sinister_locations() {
      const participants = this.lead?.participants ? this.lead.participants : [];
      return participants
        .map(participant => ({
          ...participant.risk_location,
          participant
        }));
    },
    sinister_participants() {
      return this.lead?.participants ? this.lead.participants : [];
    },
    scheduling_type() {
      const type = this.scheduling.type;
      return type ? type : {};
    },
    form_scheduling_type() {
      const type = this.scheduling_types.find(type => type.id == this.value.type_id);
      return type ? type : {};
    },
    can_select_participant() {
      return this.form_scheduling_type?.needs_participant_only; 
    },
    can_select_address() {
      return this.form_scheduling_type?.needs_address_input; 
    },
    insured_information() {
      const insured = this.lead?.participant_insured;

      return {
        name: insured?.contact?.name,
        type: insured?.type,
        contacts: insured?.contact.contact1 + (insured?.contact?.contact2 
                  ? '-' + insured?.contact?.contact2
                  : ''),
        address: this.getAddressText(this.lead)
      }
    },
    ...mapState("scheduling_types", ["scheduling_types"])
  },
  created() {
    this.loadSchedulingTypes();
    this.scheduling = this.value;
  },
  beforeDestroy() {
    this.$refs.form.reset();
  }
}
</script>

<style>

</style>