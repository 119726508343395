<template>
  <div>
    <v-slide-x-transition>
      <ChooseSchedulingObjective
        v-if="step == 1"
        v-model="form"
        @input="handleObjectiveChange"
      />
      <div v-if="step == 2">
        <SchedulingForm          
          v-model="form"
          :lead="lead"
          @input="handleFormChange"
          @submit="$listeners.submit"
        />
      </div>
    </v-slide-x-transition>
  </div>  
</template>

<script>
import ChooseSchedulingObjective from "@/components/Lead/Scheduling/ChooseSchedulingObjective";
import SchedulingForm from "@/components/Lead/Scheduling/SchedulingForm";

export default {
  components: { ChooseSchedulingObjective, SchedulingForm },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    step: {
      type: Number,
      default: 1
    },
    lead: {
      type: Object
    }
  },
  data: () => ({
    submitting: false
  }),
  methods: {  
    handleObjectiveChange(form) {
      this.$emit("changeStep", 2);
      this.handleFormChange(form);
    },
    handleFormChange(form) {
      this.$emit("input", {
        ...this.value,
        ...form
      });
    }
  },
  computed: {
    form: {
      get() {
        return this.value ? this.value : {
          objective: {},
          scheduling_type: {},
          participant: {}
        };
      },
      set(value) {
        this.$emit("input", {
          ...this.value,
          ...value
        });
      }
    } 
  }
}
</script>

<style>

</style>