<template>
  <v-btn v-bind="$attrs" v-if="confirm" @click="handleClick()" color="warning">
    <slot name="confirm"> Confirmar? </slot>
  </v-btn>
  <v-btn v-bind="$attrs" v-else @click="handleConfirm()">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {},

  data() {
    return {
      confirm: false,
    };
  },

  methods: {
    handleConfirm() {
      this.confirm = true;

      setTimeout(() => {
        this.confirm = false;
      }, 3000);
    },

    handleClick() {
      this.$emit("click");
      this.confirm = false;
    },
  },
};
</script>
