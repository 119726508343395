<template>
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <ValidationObserver ref="form">
        <v-form @submit.prevent="submit()">
          <v-card-title>
            {{ __("Recusar agendamento") }}
            <v-spacer></v-spacer>
            <v-btn @click="close()" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <ValidationProvider
              name="justification_id"
              rules="required"
              v-slot="{ errors }"
            >
              <v-radio-group
                v-model="form.justification_id"
                :label="__('Selecione a justificativa')"
                name="justification_id"
                :error-messages="errors"
              >
                <v-radio
                  v-for="justification in justifications"
                  :key="justification.id"
                  :label="justification.justification"
                  :value="justification.id"
                ></v-radio>
              </v-radio-group>
            </ValidationProvider>
          </v-card-text>

          <v-card-text>
            <ValidationProvider
              name="justification"
              :rules="justificationRules"
              v-slot="{ errors }"
            >
              <v-textarea
                v-model="form.justification"
                :label="__('Justificativa')"
                filled
                counter="255"
                autofocus
                :error-messages="errors"
              ></v-textarea>
            </ValidationProvider>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close()" text> {{ __("Cancelar") }} </v-btn>
            <v-btn type="submit" color="red" dark :loading="submitting">
              <v-icon left>mdi-alert</v-icon> {{ __("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, min, max } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { refuse } from "@/services/lead_schedules.js";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import { getJustifications } from "@/services/justifications.js";
import { useJustificationTypes } from "@/constants/Justifications/JustificationTypes";
import i18n from "@/mixins/i18n";

extend("required", required);
extend("min", min);
extend("max", max);

export default {
  name: "refuse-scheduling-dialog",
  mixins: [i18n, ToastsMixin],
  data() {
    return {
      i18n_prefix: "schedulings",
      dialog: false,
      scheduling: null,
      submitting: false,
      justifications: [],
      form: {
        justification: "",
        justification_id: "",
      },
    };
  },

  computed: {
    justificationRules() {
      if (
        this.justifications.find((j) => j.id === this.form.justification_id)
          ?.justification === "Outra"
      ) {
        return "required|min:5|max:255";
      }
      return "min:5|max:255";
    },
  },

  methods: {
    resetForm() {
      this.form = {
        justification: "",
        justification_id: "",
      };
    },

    open(scheduling) {
      this.resetForm();
      this.scheduling = scheduling;
      this.dialog = true;
      this.loadJustifications();
    },

    close() {
      this.dialog = false;
    },

    async submit() {
      const validation = await this.$refs.form.validate();
      if (!validation) return;

      this.submitting = true;

      try {
        const response = await refuse(this.scheduling.id, this.form);
        this.$emit("refused", response.data);
        this.close();
        this.toast("O Agendamento foi recusado!");
      } catch (error) {
        console.error(error);
        this.$store.commit("error", "Não foi possível recusar o agendamento");
      } finally {
        this.submitting = false;
      }
    },

    async loadJustifications() {
      const { LEAD_SCHEDULING_REFUSE } = useJustificationTypes();
      this.loading = true;

      this.justifications = await getJustifications(LEAD_SCHEDULING_REFUSE);

      this.loading = false;
    },
  },
};
</script>
