<template>
  <v-dialog
    v-model="dialog"
    width="900"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title>
        {{ __("Agendamento") }}
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- <v-toolbar flat elevation="0">
        <v-spacer></v-spacer>
        <v-btn color="primary" text> Agendar </v-btn>
      </v-toolbar> -->
      <v-divider></v-divider>

      <v-card-text class="pt-5" style="background-color: #f1f1f1">
        <v-row>
          <v-col>
            <v-card style="min-height: 100%">
              <v-card-subtitle> {{ __("Agendamento") }} </v-card-subtitle>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Tipo") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ scheduling.scheduling_type.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Status") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      <SchedulingStatusChip :status="scheduling.status" label />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Data de início") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ `${scheduling.created_at}` }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="scheduling.expires_at">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Prazo para contacto") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{
                        scheduling.expires_at +
                        " (" +
                        scheduling.expires_at_diff +
                        ")"
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Data da marcação") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ scheduling.scheduled_at || "---" }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Duração") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{
                        scheduling.scheduling_duration !== null
                          ? `${scheduling.scheduling_duration}h`
                          : "---"
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col>
            <v-card style="min-height: 100%">
              <v-card-subtitle> {{ __("Marcação") }} </v-card-subtitle>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Data marcada") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{
                        scheduling.date
                          ? `${scheduling.date} ${scheduling.time}`
                          : "---"
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="scheduling.expected_end_date">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Data prevista para finalização
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ scheduling.expected_end_date || "---" }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Responsável pela marcação") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ scheduling.scheduled_by?.name || "---" }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="scheduling.canceled_by">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Responsável pelo cancelamento") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ scheduling.canceled_by.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Justificativa de atraso") }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-wrap">
                      {{ scheduling.delay_justification || "---" }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Justificativa") }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-wrap">
                      {{ scheduling.justification_description || "---" }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Detalhes da justificativa") }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-wrap">
                      {{ scheduling.justification_message || "---" }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-subtitle>
                {{ __("Tentativas de contacto") }}
              </v-card-subtitle>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("Próxima tentativa de contacto") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ scheduling.next_attempt_at || "---" }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("1ª Tentativa de contacto") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ scheduling.first_attempt_at || "---" }}
                      <v-btn
                        class="ml-2"
                        v-if="scheduling.first_attempt_at"
                        @click="
                          openEdit(
                            '1ª Tentativa de contacto',
                            scheduling.first_attempt_at,
                            attempts.FIRST_ATTEMPT
                          )
                        "
                        color="primary"
                        small
                        outlined
                      >
                        Alterar
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("2ª Tentativa de contacto") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ scheduling.second_attempt_at || "---" }}
                      <v-btn
                        class="ml-2"
                        v-if="scheduling.second_attempt_at"
                        @click="
                          openEdit(
                            '2ª Tentativa de contacto',
                            scheduling.second_attempt_at,
                            attempts.SECONT_ATTEMPT
                          )
                        "
                        color="primary"
                        small
                        outlined
                      >
                        Alterar
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ __("3ª Tentativa de contacto") }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ scheduling.third_attempt_at || "---" }}
                      <v-btn
                        class="ml-2"
                        v-if="scheduling.third_attempt_at"
                        @click="
                          openEdit(
                            '3ª Tentativa de contacto',
                            scheduling.third_attempt_at,
                            attempts.THIRD_ATTEMPT
                          )
                        "
                        color="primary"
                        small
                        outlined
                      >
                        Alterar
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <EditAttemptDateDialog
        ref="open_dialog"
        :title="title_edit"
        :date="date_attempt"
        :scheduling="scheduling"
        :attempt="attempt"
        @reload="reload"
      ></EditAttemptDateDialog>
    </v-card>
  </v-dialog>
</template>

<script>
import EditAttemptDateDialog from "./EditAttemptDateDialog.vue";
import SchedulingStatusChip from "./SchedulingStatusChip.vue";
import i18n from "@/mixins/i18n";
import { useContactAttempts } from "@/constants/ContactAttempts";

export default {
  name: "LeadschedulingDialog",
  mixins: [i18n],
  components: {
    SchedulingStatusChip,
    EditAttemptDateDialog,
  },

  data() {
    return {
      i18n_prefix: "schedulings",
      dialog: false,
      scheduling: null,
      title_edit: "",
      date_attempt: "",
      attempt: "",
      attempts: useContactAttempts(),
      loading: false,
    };
  },

  props: {
    lead_identifier_code: String,
  },

  methods: {
    setScheduling(scheduling) {
      this.scheduling = scheduling;
    },

    open(scheduling) {
      this.setScheduling(scheduling);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    openEdit(title, date, attempt) {
      this.title_edit = title;
      this.date_attempt = date;
      this.attempt = attempt;
      this.$refs.open_dialog.open();
    },

    reload(leadScheduling) {
      this.$emit("reload", leadScheduling);
      this.setScheduling(scheduling);
    },
  },
};
</script>
