export function useContactAttempts() {
  const FIRST_ATTEMPT = "first_attempt";
  const SECONT_ATTEMPT = "second_attempt";
  const THIRD_ATTEMPT = "third_attempt";

  return {
    FIRST_ATTEMPT,
    SECONT_ATTEMPT,
    THIRD_ATTEMPT,
  };
}
