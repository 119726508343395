<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        Registar tentativa de contacto
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-actions>
        <div class="ml-2">
          <ConfirmButton
            @click="handleFailureToContact(scheduling)"
            color="primary"
          >
            Agora
          </ConfirmButton>
        </div>
        <div class="ml-2">
          <v-btn color="primary" @click="openDialog"> Retroativo </v-btn>
        </div>
        <ContactAttemptRegistrationDialog
          :scheduling="scheduling"
          ref="open_dialog"
          @reload="reload()"
          @close="close()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import ValidationErrorsMixin from "@/mixins/ValidationErrorsMixin.vue";
import { failureToContact } from "@/services/lead_schedules.js";
import ContactAttemptRegistrationDialog from "./ContactAttemptRegistrationDialog.vue";
import ConfirmButton from "@/components/Confirms/ConfirmButton.vue";

export default {
  components: {
    ContactAttemptRegistrationDialog,
    ConfirmButton,
  },
  data() {
    return {
      dialog: false,
      schedules: [],
      form: {
        date: null,
        time: null,
      },
    };
  },
  mixins: [ToastsMixin, ValidationErrorsMixin],
  props: {
    scheduling: Array,
  },
  methods: {
    openDialog() {
      this.$refs.open_dialog.open();
    },
    close() {
      this.dialog = false;
    },

    open(scheduling) {
      this.scheduling = scheduling;
      this.dialog = true;
      this.resetForm();
    },
    reload() {
      this.$emit("reload");
    },
    updateScheduling(scheduling) {
      this.schedules = this.schedules.map((item) => {
        if (item.id !== scheduling.id) {
          return item;
        }

        return { ...scheduling };
      });
    },

    async handleFailureToContact(scheduling) {
      try {
        const response = await failureToContact(scheduling.id, null);

        this.updateScheduling(response.data);

        if (response.data.status === "letter") {
          this.toast(
            "Tentativa de contactar registada. O limite de tentativas foi alcançado"
          );
        } else {
          this.toast("Tentativa de contactar registada");
        }
        this.$store.dispatch("refreshLead");
        this.dialog = false;
        this.reload();
      } catch (error) {
        if (error.response?.status === 400) {
          this.toastError(error.response?.data?.message);
          return;
        }

        this.toastError(
          "Ocorreu uma falha ao registar a tentativa de contacto"
        );
      }
    },
    handleContactAttemptRegistration(scheduling) {
      this.$refs["contact_attempt_dialog"].open(scheduling);
    },
  },
};
</script>
